.common-page {
  padding-top: 36px;
  margin: $masthead-height auto 0;
  @include page-width();

  @include responsive("DESKTOP_SMALL") {
    @include page-width("DESKTOP_SMALL");
  }
  @include responsive("TABLET") {
    padding-top: 24px;
    width: 100%;
  }

  &_header {
    h1 {
      font-family: "Inter";
      margin-bottom: 12px;
      font-size: 24px;
      font-weight: 700;
      text-transform: uppercase;
    }
    h5 {
      display: flex;
      flex-direction: column;
      line-height: 24px;
      font-size: 16px;
      font-weight: 400;
      color: #bec3e1;

      @include responsive("MOBILE_SMALL") {
        display: block;
        font-size: 14px;
      }
    }
  }

  &_filters {
    display: flex;
    align-content: center;
    margin-top: 33px;
  }

  &_content {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid #43435d;
  }
}
