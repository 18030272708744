.pro-page_bg {
  background-position: "50% -60px";
  background-repeat: "no-repeat";

  @include responsive("MOBILE_LARGE") {
    background-size: contain;
    background-position: top;
  }
}

.pro-page {
  min-height: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: $masthead-height auto 0;
  padding-top: 24px;
  box-sizing: content-box;
  @include page-width();

  @include responsive("DESKTOP_SMALL") {
    @include page-width("DESKTOP_SMALL");
  }
  @include responsive("TABLET") {
    @include page-width("TABLET");
  }
  @include responsive("MOBILE_LARGE") {
    @include page-width("MOBILE_LARGE");
    flex-direction: column;
  }
  @include responsive("MOBILE_SMALL") {
    @include page-width("MOBILE_SMALL");
  }

  &.pro-page_loading {
    padding: 200px 0;
    svg {
      width: 50px;
    }
  }

  .pro-match-ads {
    &.pro-match-ads_1 {
      margin-bottom: 24px;
    }
    &.pro-match-ads_2 {
      margin: 24px 0;
    }

    .ad-slot {
      width: 885px;
      @include responsive("DESKTOP_SMALL") {
        width: 990px;
      }
      @include responsive("TABLET") {
        width: 740px;
      }
      @include responsive("MOBILE_LARGE") {
        width: 632px;
      }
      @include responsive("MOBILE_SMALL") {
        width: 336px;
      }
    }
  }
  .pro-side-column-ads {
    margin-top: 12px;
  }

  .pro-page_content {
    display: grid;
    grid-template-columns: 1fr 300px;

    grid-gap: 24px 24px;
    margin: 0 auto;
    padding-top: 24px;
    width: 100%;
    border-top: 1px solid #43435d;

    &.pro-page_content__loading {
      display: flex;
      padding-top: 100px;
      svg {
        width: 50px;
      }
    }

    @include responsive("DESKTOP_SMALL") {
      grid-template-rows: auto auto;
      grid-template-columns: 1fr;
    }
    .header-wrapper {
      position: relative;
      display: grid;
      grid-template-columns: 10% 25% 9% 10% 5% 9% 26%;
      .header_2 {
        padding-left: 53px;
      }
      .header_5 {
        padding-left: 1px;
      }
      @include responsive("TABLET") {
        grid-template-columns: 11% 19% 11% 10% 6% 10% 27%;

        .header_2 {
          padding-left: 48px;
        }
        .header_5 {
          //text-align: center;
          padding-left: 10px;
        }
      }
      @include responsive("MOBILE_LARGE") {
        grid-template-columns: 15% 26% 11% 9% 31%;
        .header_2 {
          padding-left: 50px;
        }
        .header_3 {
          display: none;
        }
        .header_6 {
          display: none;
        }
        .header_5 {
          text-align: left;
          padding-left: 5px;
        }
      }
      @include responsive("MOBILE_SMALL") {
        display: none;
      }
      grid-auto-flow: column;
      //padding: 12px 0;

      margin-bottom: 12px;
      color: #bec3e1;
      font-size: 11px;
      font-weight: 400;
      .column-header {
        padding-top: 12px;
      }

      .section-title {
        margin-right: 12px;
        padding-top: 7px;
        //padding-bottom: 0px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .filter-manager {
      margin-top: 0;
      margin-bottom: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid #43435d;
    }

    .load-more-container {
      @include flex-center;
      margin-top: 30px;

      svg {
        width: 50px;
        flex: 0 0 50px;
      }
    }

    .match-list {
      &.match-list_loading {
        padding: 100px 0 300px;
        svg {
          width: 50px;
        }
      }
      &.no-results {
        grid-column: 1 / 2;
        @include flex-center;
        flex-direction: column;
        padding: 0 36px;
        height: 320px;
        border-radius: 4px;
        background-color: #1d1d39;
        color: #bec3e1;
        font-size: 16px;
        font-weight: 500;
        text-align: center;

        a {
          font-weight: 700;
          text-decoration: underline;
          &:hover {
            color: #3273fa;
          }
        }

        .chibi {
          margin-bottom: 24px;
          width: 144px;
        }

        .ugg-logo {
          position: relative;
          top: 2px;
          margin-left: 4px;
          width: 48px;
          path {
            fill: #ffffff;
          }
        }
      }
    }
  }
}

.pro-achievements {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: #1d1d39;

  .achievements-list {
    flex: 1;
    padding: 12px;

    .achievement {
      display: flex;
      align-items: center;

      .medal-icon {
        @include flex-center;
        margin-right: 6px;
        flex: 0 0 24px;
        width: 24px;
        height: 24px;
        img {
          width: 12px;
        }
      }
      .achievement-text {
        color: #bec3e1;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .source-footer {
    padding: 5px 6px;
    border-radius: 0 0 4px 4px;
    background-color: #252542;
    font-size: 11px;
    color: #ffffff;
    text-align: right;

    span:first-child {
      color: #bec3e1;
    }
  }
}

@import "./pro-side-column";
@import "./pro-header";
