.settings-page {
  margin: $masthead-height auto 0;
  padding-top: 24px;
  width: 1200px;

  @include responsive("DESKTOP_SMALL") {
    @include page-width("DESKTOP_SMALL");
  }
  @include responsive("TABLET") {
    @include page-width("TABLET");
  }
  @include responsive("MOBILE_LARGE") {
    @include page-width("MOBILE_LARGE");
    flex-direction: column;
  }
  @include responsive("MOBILE_SMALL") {
    @include page-width("MOBILE_SMALL");
  }

  .settings-header {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #43435d;

    h1 {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .cog-icon {
        margin-right: 12px;
        width: 18px;
      }
    }
  }
}
