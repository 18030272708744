.match-build {
  display: grid;
  grid-template-rows: auto minmax(136px, auto) auto;
  grid-template-columns: 246px 1fr;

  .grid-title {
    margin-bottom: 12px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
  }

  .runes {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    grid-area: 1 / 1 / 2 / 3;
    padding-top: 24px;
    padding-bottom: 12px;
    // border-bottom: 1px solid #454566;

    .secondary-tree {
      @include flex-center;
      flex-direction: column;
      margin-left: 100px;

      .divider {
        margin: 20px 0;
        width: 100%;
        height: 1px;
        background-color: #454566;
      }
    }
  }

  .completed-items {
    grid-area: 2 / 1 / 3 / 2;
    padding: 24px 0;
    border-top: 1px solid #454566;
    border-right: 1px solid #454566;

    .items {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: -12px;

      > * {
        margin-bottom: 12px;
      }

      .arrow {
        margin-right: 6px;
        width: 6px;
      }

      .item-image {
        @include flex-center;
        // padding: 1px;
        width: 42px;
        height: 42px;
        border-radius: 2px;
        border: 1px solid #454566;
        // background-color: #454566;

        &:not(:last-child) {
          margin-right: 6px;
        }

        &.completed-item {
          // background: #d8d8d8;
          border: 1px solid $completed-item-border-color;
        }
        &.mythic-item {
          border: 1px solid $mythic-item-border-color;
          // background: linear-gradient(to bottom right, #F8B218, #7C5501);
        }
        &.completed-item,
        &.mythic-item {
          > img {
            border: 1px solid $inner-item-border-color;
          }
        }

        img {
          width: 40px;
          // border-radius: 15%;
        }
      }
    }
  }

  .item-path {
    grid-area: 2 / 2 / 3 / 3;
    padding: 24px;
    border-top: 1px solid #454566;

    .item-timeline {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: -12px;

      .items-purchased-timeline {
        @include flex-center;
        margin-bottom: 12px;

        .visit {
          @include flex-center;
          flex-direction: column;

          .items-purchased-by-minute {
            display: flex;
            align-items: center;
            padding: 6px;
            background-color: #454566;

            .item {
              position: relative;

              &:not(:last-child) {
                margin-right: 6px;
              }

              .item-image {
                // padding: 1px;
                border-radius: 15%;
                border: 1px solid transparent;

                &.completed-item {
                  // background: #d8d8d8;
                  border: 1px solid $completed-item-border-color;
                }
                &.mythic-item {
                  border: 1px solid $mythic-item-border-color;
                  // background: linear-gradient(to bottom right, #F8B218, #7C5501);
                }
                &.completed-item,
                &.mythic-item {
                  > div {
                    border: 1px solid $inner-item-border-color;
                  }
                }

                > div {
                  border-radius: 15%;
                  overflow: hidden;
                }
              }
              // > div:first-child {
              //   border-radius: 15%;
              //   overflow: hidden;
              // }
              .item-count {
                @include flex-center;
                position: absolute;
                z-index: 2;
                right: -3px;
                bottom: -3px;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: rgba(0, 0, 0, 0.7);
                font-size: 10px;
                font-weight: 500;
                cursor: default;
                pointer-events: none;
              }
              .item-sold {
                position: absolute;
                z-index: 1;
                left: 1px;
                right: 1px;
                top: 1px;
                bottom: 1px;
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 15%;
                cursor: default;
                pointer-events: none;

                svg {
                  position: absolute;
                  bottom: 2px;
                  left: 2px;
                  width: 12px;
                  fill: #ff4e50;
                }
              }
            }
          }
          .timestamp {
            margin-top: 6px;
            color: #cddcfe;
            font-size: 10px;
          }
        }
        .separator {
          position: relative;
          top: -8px;
          width: 12px;
          height: 12px;
          background-color: #454566;
        }
      }
    }
  }

  .abilities {
    grid-area: 3 / 1 / 4 / 3;
    padding-top: 24px;
    border-top: 1px solid #454566;
    overflow-x: auto;
  }

  @include responsive("MOBILE_LARGE") {
    display: flex;
    flex-direction: column;

    .completed-items {
      border-right: none;
    }

    .item-path {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include responsive("MOBILE_SMALL") {
    .runes {
      flex-wrap: wrap;
      padding-bottom: 24px;

      .secondary-tree {
        margin: 0;
      }
    }
  }
}
