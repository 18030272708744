$breakpoints: (
  "MOBILE_SMALL": 0px 640px,
  "MOBILE_LARGE": 641px 800px,
  "TABLET": 801px 1024px,
  "DESKTOP_SMALL": 1025px 1300px,
  "DESKTOP_LARGE": 1301px 999999px,
);

@include generate-media-queries($breakpoints);

// Used for pure css responsive
@mixin responsive($breakpoint, $cascade: true) {
  @if ($cascade == false) {
    @media (min-width: nth(map-get($breakpoints, $breakpoint), 1)) and (max-width: nth(map-get($breakpoints, $breakpoint), 2)) {
      @content;
    }
  } @else {
    @media (max-width: nth(map-get($breakpoints, $breakpoint), 2)) {
      @content;
    }
  }
}
