.rune-tree-container {
  margin-top: 24px;
  padding-bottom: 8px;

  .rune-tree {
    width: 210px;
    border-left: 1px solid #454566;

    .path-main {
      display: flex;
      align-items: center;
      position: relative;
      top: -24px;
      padding-left: 24px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 24px;
        height: 1px;
        background-color: #454566;
      }

      .path-image-container {
        @include flex-center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: #070720;

        > img {
          width: 42px;
          border-radius: 50%;
        }
      }
      .perk-style-title {
        margin-left: 12px;
        font-size: 14px;
      }
    }

    .keystones,
    .perks {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // width: 180px;

      &::before {
        content: "";
        position: absolute;
        left: -5px;
        // top: calc(50% - 5px);
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: #ffffff;
      }

      &.zero-active {
        &::before {
          background-color: #454566;
        }
      }

      .perk {
        @include flex-center;
        // flex: 1;
        opacity: 0.25;
        filter: grayscale(100%);

        &.perk_active {
          opacity: 1;
          filter: grayscale(0);
        }
      }
    }

    .keystones {
      position: relative;
      padding-left: 13px;
      padding-right: 3px;
      padding-bottom: 20px;
      border-bottom: 1px solid #454566;

      &::before {
        background-color: #3273fa;
      }
      .perk {
        width: 48px;
        height: 48px;
        img {
          width: 48px;
        }
      }
    }

    .sub-perks {
      margin-top: -30px;

      &.is-primary {
        margin-top: 8px;

        .perks {
          margin-bottom: 30px;
        }
      }

      .perks {
        position: relative;
        top: 18px;
        margin-bottom: 12px;
        padding-left: 20px;
        padding-right: 10px;

        &::before {
          content: "";
          position: absolute;
          left: -5px;
          width: 9px;
          height: 9px;
          border-radius: 50%;
        }

        .perk {
          width: 34px;
          height: 34px;
          &.perk_active {
            img {
              border-color: #3273fa;
            }
          }
          img {
            width: 34px;
            border-radius: 50%;
            border: 2px solid transparent;
          }
        }
      }
    }
  }
}

.stat-shards-container {
  margin: 12px 0;
  width: 210px;
  border-left: 1px solid #454566;

  .shard-rows {
    // margin: 12px 0;
  }

  .shards {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px 0 25px;

    &:first-child {
      top: -12px;
    }
    &:last-child {
      bottom: -12px;
    }

    &::before {
      content: "";
      position: absolute;
      left: -5px;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: #ffffff;
    }

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    .shard {
      @include flex-center;
      width: 24px;
      height: 24px;
      border-radius: 50px;
      border: 2px solid transparent;
      opacity: 0.25;
      filter: grayscale(100%);
      // background-color: #1d1d39;
      background-color: #28283e;

      &.shard_active {
        border-color: #3273fa;
        background-color: #17172e;
        opacity: 1;
        filter: grayscale(0);
      }

      img {
        width: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-indent: 100%;
      }
    }
  }
}
