.champion-page_bg {
  min-height: 100vh;
  background-position: 50% -60px;
  background-repeat: no-repeat;

  @include responsive("MOBILE_LARGE") {
    background-size: contain;
    background-position: top;
  }
}

.champion-page {
  min-height: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: $masthead-height auto 0;
  padding-top: 24px;
  box-sizing: content-box;
  @include page-width();

  @include responsive("DESKTOP_SMALL") {
    @include page-width("DESKTOP_SMALL");
  }
  @include responsive("TABLET") {
    @include page-width("TABLET");
  }
  @include responsive("MOBILE_LARGE") {
    @include page-width("MOBILE_LARGE");
    flex-direction: column;
  }
  @include responsive("MOBILE_SMALL") {
    @include page-width("MOBILE_SMALL");
  }

  .champion-match-ads {
    &.champion-match-ads_1 {
      margin-top: 24px;
    }
    &.champion-match-ads_2 {
      margin: 24px 0;
    }

    .ad-slot {
      width: 885px;
      @include responsive("DESKTOP_SMALL") {
        width: 990px;
      }
      @include responsive("TABLET") {
        width: 740px;
      }
      @include responsive("MOBILE_LARGE") {
        width: 632px;
      }
      @include responsive("MOBILE_SMALL") {
        width: 336px;
      }
    }
  }

  .header-wrapper {
    margin-top: 12px;
    position: relative;
    display: grid;
    grid-template-columns: 10% 23% 6% 5% 8% 5% 10% 26%;

    .header_2 {
      padding-left: 53px;
    }
    .header_3 {
      padding-left: 4px;
    }
    .header_6 {
      padding-left: 1px;
    }
    .header_6 {
      padding-left: 11px;
    }
    @include responsive("TABLET") {
      grid-template-columns: 11% 19% 13% 10% 6% 10% 27%;

      .header_2 {
        padding-left: 48px;
      }
      .header_3 {
        padding-left: 13px;
      }
      .header_4 {
        display: none;
      }
      .header_6 {
        //text-align: center;
        padding-left: 10px;
      }
    }
    @include responsive("MOBILE_LARGE") {
      grid-template-columns: 15% 19% 8% 12% 8% 31%;
      .header_2 {
        padding-left: 24px;
      }
      .header_3 {
        padding-left: 10px;
      }
      .header_7 {
        display: none;
      }
      .header_6 {
        text-align: left;
        padding-left: 5px;
      }
    }
    @include responsive("MOBILE_SMALL") {
      display: none;
    }
    grid-auto-flow: column;
    //padding: 12px 0;

    //margin-bottom: 12px;
    color: #bec3e1;
    font-size: 11px;
    font-weight: 400;
    .column-header {
      padding-top: 12px;
    }

    .section-title {
      margin-right: 12px;
      padding-top: 7px;
      //padding-bottom: 0px;
      color: #ffffff;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .champion-page_filters {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    padding-bottom: 24px;
    width: 100%;
    border-bottom: 1px solid #43435d;

    .filter-description {
      display: flex;
      flex-direction: column;
      margin-left: 12px;
      font-size: 12px;

      span {
        margin-bottom: 6px;
        color: #bec3e1;
      }
      strong {
        font-weight: 500;
      }
    }

    @include responsive("TABLET") {
      flex-direction: column;
      align-items: flex-start;

      .filter-description {
        flex-direction: row;
        margin-left: 0;
        margin-top: 12px;
      }
    }
    @include responsive("MOBILE_SMALL") {
      .filter-description {
        display: inline;
        margin-top: 18px;
      }
    }
  }

  .champion-page_content {
    display: grid;
    grid-template-rows: auto 1fr auto auto;
    @include responsive("MOBILE_SMALL") {
      grid-template-rows: auto auto 1fr auto auto;
    }
    grid-template-columns: 1fr 300px;
    grid-gap: 0 24px;
    margin: 0 auto;
    width: 100%;

    &.champion-page_content__loading {
      display: flex;
      padding-top: 100px;
      svg {
        width: 50px;
      }
    }

    .section-title {
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 700;
    }

    .load-more-container {
      grid-area: 3 / 1 / 4 / 2;
      @include flex-center;
      margin-top: 30px;

      svg {
        width: 50px;
        flex: 0 0 50px;
      }
    }

    .match-list {
      &.match-list_loading {
        padding: 100px;
        svg {
          width: 50px;
        }
      }
      &.top-players {
        grid-area: 3 / 1 / 4 / 2;
        margin-bottom: 24px;
        padding-bottom: 24px;
        //border-bottom: 1px solid #43435d;

        .top-players_header {
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            color: #bec3e1;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
      &.all-players {
        grid-area: 2 / 1 / 3 / 2;
        @include responsive("MOBILE_SMALL") {
          margin-top: 24px;
        }
        .all-players_header {
          margin-top: 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            color: #bec3e1;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
      &.no-results {
        grid-area: 2 / 1 / 3 / 2;
        @include flex-center;
        flex-direction: column;
        padding: 0 36px;
        height: 320px;
        border-radius: 4px;
        background-color: #1d1d39;
        color: #bec3e1;
        font-size: 16px;
        font-weight: 500;
        text-align: center;

        a {
          font-weight: 700;
          text-decoration: underline;
          &:hover {
            color: #3273fa;
          }
        }

        .chibi {
          margin-bottom: 24px;
          width: 144px;
        }

        .ugg-logo {
          position: relative;
          top: 2px;
          margin-left: 4px;
          width: 48px;
          path {
            fill: #ffffff;
          }
        }
      }

      .match-summary-container {
        .match-summary-champion {
          .grid-item-player-info {
            .team {
              .team-logo,
              .otp-logo {
                margin-left: 0px;
                margin-right: 6px;
              }
            }
          }
        }
      }
    }

    @include responsive("DESKTOP_SMALL") {
      grid-template-rows: auto auto auto auto;
      grid-template-columns: 1fr;

      .load-more-container {
        grid-area: 4 / 1 / 5 / 2;
      }

      .match-list {
        &.top-players {
        }
        &.all-players {
          grid-area: 3 / 1 / 4 / 2;
        }
        &.no-results {
          grid-area: 3 / 1 / 4 / 2;
        }
      }
    }
  }
}

@import "./champion-header";
@import "./champion-side-column";

@import "./champion-top-bar";
