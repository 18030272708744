// Used for pure css responsive
// @mixin responsive($breakpoint, $cascade: true) {
//   @if (map-get($breakpoints, $breakpoint) == null) {
//     @error "Breakpoint '#{$breakpoint}' does not exist.";
//   }

//   @if ($cascade == false) {
//     @media (min-width: nth(map-get($breakpoints, $breakpoint), 1)) and (max-width: nth(map-get($breakpoints, $breakpoint), 2)) {
//       @content;
//     }
//   } @else {
//     @media (max-width: nth(map-get($breakpoints, $breakpoint), 2)) {
//       @content;
//     }
//   }
// }

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// Used for MediaQuery component
// https://www.sassmeister.com/gist/4c5a106789d2b939b604a744fd2e24fa
// Link above to preview scss -> css output
@mixin generate-media-queries($breakpoints) {
  $numOfBreakpoints: length($breakpoints);

  @for $i from 1 through $numOfBreakpoints {
    @for $j from $i through $numOfBreakpoints {
      @if ($i != 1 or $j != $numOfBreakpoints) and $j <= $numOfBreakpoints {
        $breakpointName1: to-upper-case(str-replace(nth(map-keys($breakpoints), $i), "-", "_"));
        // $breakpointValue1: nth(map-values($breakpoints), $i);
        $breakpointName2: to-upper-case(str-replace(nth(map-keys($breakpoints), $j), "-", "_"));
        // $breakpointValue2: nth(map-values($breakpoints), $j);

        @if $i == 1 and $j == 1 {
          @media (min-width: nth(nth(map-values($breakpoints), $i + 1), 1)) {
            .media-query_#{$breakpointName1} {
              display: none !important;
              margin: 0 !important;
            }
          }
        } @else if $i == $numOfBreakpoints and $j == $numOfBreakpoints {
          @media (max-width: nth(nth(map-values($breakpoints), $i - 1), 2)) {
            .media-query_#{$breakpointName1} {
              display: none !important;
              margin: 0 !important;
            }
          }
        } @else {
          @if $i == 1 and $j != $numOfBreakpoints {
            @media (min-width: nth(nth(map-values($breakpoints), $j + 1), 1)) {
              .media-query_#{$breakpointName1}__#{$breakpointName2} {
                display: none !important;
                margin: 0 !important;
              }
            }
          } @else if $j == $numOfBreakpoints {
            @media (max-width: nth(nth(map-values($breakpoints), $i - 1), 2)) {
              .media-query_#{$breakpointName1}__#{$breakpointName2} {
                display: none !important;
                margin: 0 !important;
              }
            }
          } @else {
            @media (max-width: nth(nth(map-values($breakpoints), $i - 1), 2)),
              (min-width: nth(nth(map-values($breakpoints), $j + 1), 1)) {
              .media-query_#{$breakpointName1}#{if($i != $j, __#{$breakpointName2}, "")} {
                display: none !important;
                margin: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}
