.mobile-menu-container {
  position: fixed;
  top: $masthead-height;
  left: 0;
  right: 0;
  z-index: 99999;
  transform: translateY(calc(-100% - #{$masthead-height} - 10px));
  transition: transform 0.15s ease-out;

  &_open {
    transform: translateY(0%);
  }

  .mobile-menu_backdrop {
    position: absolute;
    z-index: -1;
    width: 200vw;
    height: 200vh;
    background: #070720;
    opacity: 0.75;
  }

  .mobile-menu {
    display: flex;
    flex-direction: column;
    padding: 0 18px;
    box-shadow: 0 2px 4px #070720;
    border-bottom: 1px solid #31334a;
    background-color: #111129;
    .world-logo {
      //margin-bottom: -3px;
      margin-right: 4px;

      width: 17px;
      height: 17px;
    }
    a {
      display: flex;
      align-items: center;
      padding: 18px;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;

      &:not(:last-child) {
        border-bottom: 1px solid #43435d;
      }

      .new-tag {
        margin-left: 6px;
        width: 30px;
      }

      &.active {
        span {
          position: relative;
          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -4px;
            width: 100%;
            height: 2px;
            border-radius: 1px;
            background-color: #3273fa;
          }
        }
      }
    }
  }
}
