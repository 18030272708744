.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -$masthead-height;
  padding-top: $masthead-height + 80px;
  width: 100%;
  min-height: calc(100% + 120px);
  // background-size: contain;
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
  background-image: url("https://static.bigbrain.gg/assets/probuildstats/pages/landing-page/landing-bg.png");

  @include responsive("MOBILE_LARGE") {
    padding-top: $masthead-height + 24px;
    padding-bottom: 24px;
  }
  @include responsive("MOBILE_LARGE") {
    padding-top: $masthead-height + 16px;
  }

  &.menu-open {
    margin-top: 0;
  }

  .landing-page-ads {
    margin-top: 36px;
    margin-bottom: -12px;
    .ad-slot {
      width: 1200px;

      @include responsive("DESKTOP_SMALL") {
        @include page-width("DESKTOP_SMALL");
      }
      @include responsive("TABLET") {
        @include page-width("TABLET");
      }
      @include responsive("MOBILE_LARGE") {
        @include page-width("MOBILE_LARGE");
      }
      @include responsive("MOBILE_SMALL") {
        @include page-width("MOBILE_SMALL");
      }
    }
  }

  .divider {
    margin: 60px 0;
    max-width: 1200px;
    width: 90%;
    height: 1px;
    background-color: #1d1d39;

    @include responsive("MOBILE_SMALL") {
      margin: 24px 0;
    }
  }
  .landing-header-wrapper {
    display: grid;
    grid-template-columns: 136px 1fr;
    grid-template-rows: 50px 1fr;
    grid-template-areas:
      "logo header"
      "logo sheader";
    margin-bottom: 60px;
    .logo {
      grid-area: logo;
      //margin-right: 30px;
      margin-top: 11px;
      //margin-bottom: 12px;
      height: 134px;

      /*@include responsive("MOBILE_LARGE") {
        height: 90px;
      }*/
      @include responsive("MOBILE_SMALL") {
        height: 100px;
      }
    }

    .landing_header {
      grid-area: header;
      font-family: "Inter";

      letter-spacing: normal;
      line-height: normal;
      font-size: 48px;
      font-weight: 400;
      .enlarge {
        font-weight: 800;
      }
      @include responsive("MOBILE_LARGE") {
        font-size: 35px;
      }
      @include responsive("MOBILE_SMALL") {
        margin-bottom: 8px;
        font-size: 22px;
      }
    }
    .landing_sub-header-1 {
      grid-area: sheader;
      margin-top: 22px;
      //margin-bottom: 60px;
      color: #bec3e1;
      font-family: "Inter";

      letter-spacing: normal;
      line-height: normal;
      font-size: 20px;
      font-weight: 400;
      text-align: left;

      /*br {
        display: none;
      }*/

      @include responsive("MOBILE_LARGE") {
        br {
          display: block;
        }
      }
      @include responsive("MOBILE_SMALL") {
        margin-bottom: 24px;
        //margin-top: 22px;
        font-size: 16px;
      }
    }
  }

  .landing_sub-header-2 {
    max-width: 720px;
    color: #bec3e1;
    font-size: 24px;
    font-weight: 400;
    text-align: center;

    strong {
      color: #ffffff;
    }

    @include responsive("MOBILE_SMALL") {
      font-size: 16px;
    }
  }

  .landing-page_search {
    //margin-bottom: 10px;
  }

  .webm-placeholder {
    margin-top: 36px;
    max-width: 720px;
    width: 100%;
    height: 405px;
    flex: 0 0 405px;
    background-color: #1d1d39;
  }

  .landing_section {
    .header_container {
      display: grid;
      max-width: 100%;
      justify-content: space-between;
      @include responsive("DESKTOP_LARGE") {
        width: 990px;
      }

      @include responsive("TABLET") {
        width: 800px;
      }

      @include responsive("MOBILE_LARGE") {
        width: 640px;
      }

      @include responsive("MOBILE_SMALL") {
        width: 336px;
      }
      // grid-template-columns: 268px 700px;

      // @include responsive("DESKTOP_SMALL") {
      //   grid-template-columns: 287px 700px;
      // }
      // @include responsive("TABLET") {
      //   grid-template-columns: 40% 60%;
      // }
      @include responsive("MOBILE_SMALL") {
        max-width: 336px;
      }
      .flex {
        justify-content: flex-end;
      }

      .header {
        display: flex;
        align-items: center;
        vertical-align: middle;
        grid-column: 1 / 2;
        margin-top: 5px;
        margin-bottom: 32px;
        color: #ffffff;
        font-family: "Inter";
        font-size: 20px;
        font-weight: 500;
        text-align: left;

        .live-tag {
          width: 42px;
          margin-left: 4px;
          // margin-top: -5px;
        }

        @include responsive("MOBILE_SMALL") {
          // margin-bottom: unset;
          font-size: 18px;
        }
      }
      .filter {
        grid-column: 2 / 4;
        margin-bottom: 32px;
        justify-content: right;
      }
    }
    .content_header {
      position: relative;
      display: grid;

      grid-template-columns: 9% 25% 9% 10% 6% 9% 26%;
      @include responsive("TABLET") {
        grid-template-columns: 9% 20% 10% 12% 6% 10% 26%;
      }

      @include responsive("MOBILE_LARGE") {
        grid-template-columns: 13% 26% 12% 9% 32%;
        .header_3 {
          display: none;
        }
        .header_6 {
          display: none;
        }
      }
      @include responsive("MOBILE_SMALL") {
        display: none;
      }
      grid-auto-flow: column;
      padding-bottom: 12px;
      padding-left: 12px;
      color: #bec3e1;
      font-size: 11px;
      font-weight: 400;
      .header_2 {
        padding-left: 53px;
      }
      .header_6 {
        padding-left: 4px;
      }
    }
  }

  @import "./popular-pros";
  @import "./popular-champions";
  @import "./live-game-feed";
}
