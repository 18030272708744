$match-bg-color: #111129;
$match-bg-colo-alt: #1d1d39;

.match-card {
  width: 970px;
  border-radius: 4px;
  border-left: 12px solid #1d1d39;
  background-color: $match-bg-color;

  &:not(:last-child) {
    margin-bottom: 6px;
  }

  &.even {
    background-color: $match-bg-colo-alt;
  }

  &.win {
    border-left: 12px solid #3273fa;
  }
  &.loss {
    border-left: 12px solid #ff4e50;
  }

  @include responsive("DESKTOP_SMALL") {
    width: 990px;
  }
  @include responsive("TABLET") {
    width: 800px;
  }
  @include responsive("MOBILE_LARGE") {
    width: 640px;
  }
  @include responsive("MOBILE_SMALL") {
    width: 336px;
  }

  .match-card_dropdown {
    margin: 0 12px;
    padding: 0 12px 24px;
    border-top: 1px solid #454566;

    @include responsive("TABLET") {
      padding: 0 0 24px;
    }

    .tabs {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #454566;

      .tab {
        position: relative;
        width: 100px;
        padding: 12px 0;
        text-align: center;
        font-size: 16px;

        @include responsive("MOBILE_SMALL") {
          font-size: 12px;
        }

        &:hover {
          color: #3273fa;
          cursor: pointer;
        }

        &.tab_active {
          color: #3273fa;
          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: #3273fa;
          }
        }
      }
    }
  }
}

.match-card {
  width: 970px;
  border-radius: 4px;
  border-left: 12px solid #1d1d39;
  background-color: $match-bg-color;

  &:not(:last-child) {
    margin-bottom: 6px;
  }

  &.even {
    background-color: $match-bg-colo-alt;
  }

  &.win {
    border-left: 12px solid #3273fa;
  }
  &.loss {
    border-left: 12px solid #ff4e50;
  }

  @include responsive("DESKTOP_SMALL") {
    width: 990px;
  }
  @include responsive("TABLET") {
    width: 800px;
  }
  @include responsive("MOBILE_LARGE") {
    width: 640px;
  }
  @include responsive("MOBILE_SMALL") {
    width: 336px;
  }

  .match-card_dropdown {
    margin: 0 12px;
    padding: 0 12px 24px;
    border-top: 1px solid #454566;

    @include responsive("TABLET") {
      padding: 0 0 24px;
    }

    .tabs {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #454566;

      .tab {
        position: relative;
        width: 100px;
        padding: 12px 0;
        text-align: center;
        font-size: 16px;

        @include responsive("MOBILE_SMALL") {
          font-size: 12px;
        }

        &:hover {
          color: #3273fa;
          cursor: pointer;
        }

        &.tab_active {
          color: #3273fa;
          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: #3273fa;
          }
        }
      }
    }
  }
}

@import "./match-summary";
@import "./match-build";
@import "./match-scoreboard";
@import "./world-match-summary";
