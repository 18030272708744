.champion-page_side-column {
  // position: sticky;
  // height: min-content;
  // top: 72px;
  grid-area: 1 / 2 / 3 / 3;
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px 12px;

  @include responsive("DESKTOP_SMALL") {
    display: none;
  }
  @include responsive("DESKTOP_SMALL") {
    display: none;
  }
  > * {
    grid-column: 1 / 3;
  }

  .side-column_title {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 700;
    span {
      color: #bec3e1;
      font-weight: 400;
    }
  }

  .side-column_grid-item {
    border-radius: 4px;
    background-color: #1d1d39;
  }

  .side-column_blurb {
    padding-bottom: 22px;
    border-bottom: 1px solid #43435d;
    font-size: 14px;
    line-height: 21px;
    span {
      color: #bec3e1;
    }
  }

  .champion-roles,
  .champion-runes {
    display: flex;
    flex-direction: column;

    .top-roles,
    .top-runes {
      display: flex;
      padding: 12px 0;
      min-height: 84px;
      flex: 1;

      .role,
      .runes {
        @include flex-center;
        flex-direction: column;
        flex: 1;

        @include responsive("DESKTOP_SMALL") {
          .rune,
          .role-img {
            flex: none !important;
          }
        }
        @include responsive("TABLET") {
          .rune,
          .role-img {
            flex: none !important;
          }
        }

        &:first-child {
          border-right: 1px solid #454566;
        }
      }
    }
    .rate {
      margin-top: 12px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .champion-roles {
    grid-area: 2 / 1 / 3 / 2;
    .top-roles {
      .role {
        .role-img {
          @include flex-center;
          flex: 1;
          width: 30px;
          height: 30px;
        }
        svg {
          width: 22px;
        }
      }
    }
  }
  .champion-runes {
    grid-area: 2 / 2 / 3 / 3;
    .top-runes {
      .runes {
        .rune {
          @include flex-center;
          flex: 1;
          .rune-img {
            position: relative;
            width: 30px;
            height: 30px;

            > div {
              @include flex-center;
              border-radius: 50%;
              background-color: #070720;
              > img {
                border-radius: 50%;
              }
            }

            .keystone {
              width: 30px;
              height: 30px;
              img {
                width: 30px;
              }
            }
            .secondary-tree {
              position: absolute;
              bottom: 0;
              right: -8px;
              width: 17px;
              height: 17px;
              img {
                width: 15px;
              }
            }
          }
        }
      }
    }
  }

  .champion-items {
    .top-items {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      padding: 14px 20px;

      .item {
        @include flex-center;
        flex-direction: column;
        &:not(:last-child) {
          margin-right: 16px;
        }

        .item-image {
          // padding: 1px;
          flex: 0 0 30px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: #454566;

          &.completed-item {
            // background: #d8d8d8;
            border: 1px solid $completed-item-border-color;
          }
          &.mythic-item {
            // background: linear-gradient(to bottom, #F8B218, #7C5501);
            border: 1px solid $mythic-item-border-color;
          }
          img {
            width: 100%;
            border-radius: 50%;
            border: 1px solid $inner-item-border-color;
          }
        }
        .pick-rate {
          margin-top: 10px;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  .champion-top-pros {
    .top-pros {
      padding: 12px 24px;

      a {
        display: flex;
        align-items: center;
        padding: 12px 0;
        list-style: none;

        &:not(:last-child) {
          border-bottom: 1px solid #454566;
        }

        &:hover {
          .name {
            text-decoration: underline;
          }
        }

        .rank {
          display: flex;
          align-items: center;
          flex: 0 0 20px;
          font-size: 14px;
        }

        .player {
          display: flex;
          flex: 1;

          .player-headshot {
            @include flex-center;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            background-color: #454566;
            overflow: hidden;
            img {
              width: 44px;
              flex: 0 0 44px;
            }
          }
          .player-info {
            margin-left: 12px;

            .name {
              margin-bottom: 2px;
              font-family: "Inter";
              font-size: 14px;
              font-weight: 700;
              text-transform: uppercase;
            }
            .team {
              display: flex;
              align-items: center;
              .custom-image-container {
                @include flex-center;
                .team-logo {
                  margin-left: -2px;
                  width: 14px;
                  flex: 0 0 14px;
                }
              }
              .team-name {
                margin-left: 4px;
                color: #bec3e1;
                font-size: 12px;
              }
            }
          }
        }

        .win-rate {
          margin-top: 2px;
          margin-bottom: 2px;
          font-size: 14px;
          font-weight: 500;
          text-align: right;
        }
        .total-games {
          color: #bec3e1;
          font-size: 12px;
          text-align: right;
        }
      }
    }
  }

  @include responsive("DESKTOP_SMALL") {
    grid-area: 2 / 1 / 3 / 2;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #43435d;

    @include responsive("DESKTOP_SMALL", false) {
      grid-template-rows: min-content min-content 1fr;
      grid-template-columns: repeat(4, 1fr);

      .side-column_blurb {
        grid-area: 1 / 1 / 2 / 5;
      }
      .champion-roles {
        grid-area: 2 / 3 / 3 / 4;
      }
      .champion-runes {
        grid-area: 2 / 4 / 3 / 5;
      }
      .champion-items {
        grid-area: 2 / 1 / 3 / 3;
        .item {
          &:not(:last-child) {
            margin-right: 54px !important;
          }
        }
      }
      .champion-top-regions {
        grid-area: 3 / 1 / 4 / 3;
      }
      .champion-top-pros {
        grid-area: 3 / 3 / 4 / 5;
      }
    }
  }

  @include responsive("TABLET", false) {
    grid-template-rows: min-content min-content 1fr;
    grid-template-columns: repeat(4, 1fr);

    .side-column_blurb {
      grid-area: 1 / 1 / 2 / 5;
    }
    .champion-roles {
      grid-area: 2 / 3 / 3 / 4;
    }
    .champion-runes {
      grid-area: 2 / 4 / 3 / 5;
    }
    .champion-items {
      grid-area: 2 / 1 / 3 / 3;
      .item {
        &:not(:last-child) {
          margin-right: 29px !important;
        }
      }
    }
    .champion-top-regions {
      grid-area: 3 / 1 / 4 / 3;
    }
    .champion-top-pros {
      grid-area: 3 / 3 / 4 / 5;

      .team-name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 80px;
      }
    }
  }

  @include responsive("MOBILE_LARGE", false) {
    grid-template-rows: repeat(4, min-content);
    grid-template-columns: 308px 308px;

    .side-column_blurb {
      grid-area: 1 / 1 / 2 / 3;
    }
    .champion-roles {
      grid-area: 2 / 1 / 3 / 2;
    }
    .champion-runes {
      grid-area: 2 / 2 / 3 / 3;
    }
    .champion-items {
      grid-area: 3 / 1 / 4 / 3;
      .item {
        &:not(:last-child) {
          margin-right: 82px !important;
        }
      }
    }
    .champion-top-regions {
      grid-area: 4 / 1 / 5 / 2;
    }
    .champion-top-pros {
      grid-area: 4 / 2 / 5 / 3;
    }
  }

  @include responsive("MOBILE_SMALL", false) {
    .champion-items {
      .item {
        &:not(:last-child) {
          margin-right: 23px !important;
        }
      }
    }
  }
}
