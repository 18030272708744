.post-game {
  display: flex;
  flex-direction: column;
  min-height: 640px;

  .spinner {
    padding-top: 24px;
    width: 50px;
  }
}

.match-scoreboard {
  .team-header {
    display: flex;
    align-items: center;
    font-size: 14px;

    .win {
      color: #3273fa;
      font-weight: 600;
    }
    .loss {
      color: #ff4e50;
      font-weight: 600;
    }
    .team-side {
      color: #cddcfe;
    }
  }

  .scoreboard_header,
  .player-row {
    display: grid;
    grid-template-columns: 240px 90px 95px 95px 74px 75px 240px;
    grid-auto-flow: column;

    @include responsive("DESKTOP_SMALL") {
      grid-template-columns: 236px 104px 104px 104px 80px 80px 222px;
    }
    @include responsive("TABLET") {
      grid-template-columns: 200px 66px 67px 67px 44px 44px 216px;
    }
    @include responsive("MOBILE_LARGE", false) {
      grid-template-columns: 215px 83px 83px 73px 74px 68px;
    }
  }

  .scoreboard_header {
    margin: 15px 0;
    > * {
      color: #bec3e1;
      font-size: 12px;
      font-weight: 400;
      text-align: center;

      @include responsive("MOBILE_LARGE", false) {
        &:nth-last-child(-n + 1) {
          padding-right: 12px;
          text-align: right;
        }
      }
    }
  }

  .player-row {
    height: 52px;
    border-radius: 4px;

    &.win {
      background-color: #202956;
    }
    &.loss {
      background-color: #3d233c;
    }
    &.is-current {
      border: 1px solid #ff9b00;
      background-color: #473e2f;

      .summoner-name {
        color: #ff9b00;
      }
    }

    > * {
      @include flex-center;
    }

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    .minion-icon {
      width: 7px;
    }
    .damage-icon {
      width: 7px;
      height: 10px;
    }
    .ward-icon {
      width: 10px;
    }

    .player {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 8px;

      .champion-image {
        margin-right: 14px;
      }
    }

    .champion-image {
      position: relative;
      flex: 0 0 36px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: 1px solid #2c2c40;
      @include champion-image-scale;
      overflow: hidden;
    }

    .summoner-spells {
      @include flex-center;
      flex-direction: column;
      margin-right: 2px;

      img {
        width: 18px;
        border: 1px solid #2c2c40;

        &:first-child {
          margin-bottom: 2px;
        }
      }
    }

    .runes {
      @include flex-center;
      flex-direction: column;

      .rune-image {
        @include flex-center;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #17172e;

        &:first-child {
          margin-bottom: 2px;
        }

        img {
          width: 18px;
          border-radius: 50%;
        }
      }
    }

    .summoner-name {
      margin-left: 12px;
      color: #ebebf0;
      font-size: 14px;
      font-weight: 700;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &:hover {
        text-decoration: underline;
      }
    }

    .tier {
      display: flex;
      align-items: center;

      img {
        width: 28px;
        // margin-right: 5px;
      }

      .tier-text {
        flex: 0 0 30px;
        display: flex;
        justify-content: center;
        font-size: 12px;
        font-weight: 700;
      }
    }

    .kda {
      @include flex-center;
      flex-direction: column;

      .kda-split {
        margin-bottom: 2px;
        font-size: 12px;
        font-weight: 700;
      }
      .kda-ratio {
        font-weight: 700;
        font-size: 12px;
      }
    }

    .damage {
      @include flex-center;
      flex-direction: column;

      .damage_value {
        margin-bottom: 3px;
        color: #ebebf0;
        font-size: 12px;
        font-weight: 700;
      }
      .damage_bar {
        width: 55px;
        height: 4px;
        background-color: rgba(#010916, 0.6);
        .damage_bar-progress {
          height: 100%;
          background-color: transparent;

          &.win {
            background-color: #08a6ff;
          }
          &.loss {
            background-color: #ff4e50;
          }
        }
      }
    }

    .wards,
    .cs {
      color: #ebebf0;
      font-size: 12px;
      font-weight: 700;
    }

    .items {
      display: flex;
      align-items: center;
      padding: 0 12px;

      .item-image {
        width: 24px;
        height: 24px;
        border: 1px solid #2c2c40;
        background-color: #17172e;
        box-sizing: content-box;

        &:not(:last-child) {
          margin-right: 2px;
        }

        > * {
          border-radius: 15%;
          overflow: hidden;
        }
      }
    }

    @include responsive("MOBILE_LARGE", false) {
      grid-template-rows: auto auto;
      padding: 5px 0;

      > * {
        grid-row: 1 / 3;
      }

      .summoner-name {
        max-width: 105px;
      }

      .items {
        grid-area: 1 / 4 / 2 / 7;
        justify-content: flex-end;

        .item-image {
          width: 20px;
          height: 20px;
        }
      }

      .cs,
      .damage,
      .wards {
        display: flex;
        align-items: center;
        flex-direction: row;
        color: #ffffff;
        font-size: 12px;
        font-weight: 700;

        span {
          margin-left: 4px;
        }
      }

      .cs {
        grid-area: 2 / 4 / 3 / 5;
      }
      .damage {
        grid-area: 2 / 5 / 3 / 6;
      }
      .wards {
        grid-area: 2 / 6 / 3 / 7;
        justify-content: flex-end;
        padding-right: 12px;
      }
    }

    @include responsive("MOBILE_SMALL") {
      display: block;
      height: 89px;
      padding: 12px;

      .champion-image {
        flex: 0 0 36px;
        margin-right: 0px;
      }

      .player {
        align-items: flex-start;
        flex-direction: column;
        flex: 1;
      }

      .summoner-name {
        margin-left: 0;
        margin-bottom: 4px;
        font-size: 12px;
        max-width: 90px;
      }

      .tier {
        img {
          width: 20px;
        }
        .tier-text {
          font-size: 12px;
        }
      }

      .items {
        margin-left: 12px;
        padding: 0;

        .item-image {
          width: 18px;
          height: 18px;
        }

        .main-items {
          display: grid;
          grid-template-columns: repeat(3, 20px);
          grid-gap: 2px;
        }
        .trinket-item {
          margin-left: 2px;
        }
      }

      .row-2 {
        display: grid;
        grid-template-columns: 1fr 50px 70px 50px;
        margin-top: 12px;

        .kda {
          flex: 2;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row;
          font-size: 12px;

          .kda-split {
            margin: 0;
          }
        }

        .cs,
        .damage,
        .wards {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-direction: row;
          color: #ffffff;
          font-size: 12px;
          font-weight: 700;

          span {
            margin-left: 4px;
          }
        }
      }
    }
  }
}
