html {
  font-family: "Inter", sans-serif;
  background: #070720;
  width: 100%;
  height: 100%;
  color: #ffffff;
  line-height: normal;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

svg:not(:root) {
  overflow: initial;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

a {
  color: #ffffff;
  text-decoration: none;
}

.content-side-padding {
  padding: 0 24px;
  box-sizing: border-box;

  @include responsive("MOBILE_LARGE") {
    padding: 0 12px;
  }
}

@mixin page-width($breakpoint: "DESKTOP_LARGE") {
  @if ($breakpoint == "DESKTOP_SMALL") {
    width: 990px;
  } @else if ($breakpoint == "TABLET") {
    width: 800px;
  } @else if ($breakpoint == "MOBILE_LARGE") {
    width: 630px;
  } @else if ($breakpoint == "MOBILE_SMALL") {
    width: 336px;
  } @else {
    width: 1294px;
  }
}

.inline-flex {
  display: inline-flex;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin champion-image-scale {
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: scale(1.1) translate(-45%, -45%);
  }
}

.backslash {
  color: #454566;
}

.worlds-tag-notice {
  position: relative;
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-bottom: 24px;
  height: 70px;

  .worlds-bg {
    position: absolute;
    left: 0;
    svg {
      width: 350px;
    }
  }

  img {
    position: relative;
    margin-right: 10px;
    margin-left: 14px;
    width: 44px;
  }
  .worlds-text {
    position: relative;
    font-size: 14px;
  }
}
