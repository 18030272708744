.error-section {
  @include flex-center;
  flex-direction: column;
  padding: 0 36px;
  height: 320px;
  border-radius: 4px;
  background-color: #1d1d39;
  color: #bec3e1;
  font-size: 16px;
  font-weight: 500;
  text-align: center;

  .chibi {
    margin-bottom: 24px;
    width: 144px;
  }
}
