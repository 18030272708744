.page-nav {
  display: flex;
  align-items: center;

  .page-nav_link {
    padding: 10px 0;
    border-bottom: 3px solid transparent;
    font-size: 16px;
    font-weight: 500;

    &:not(:last-child) {
      margin-right: 30px;
    }
    &.active {
      color: #3273fa;
      border-bottom: 3px solid #3273fa;
    }
    &:hover {
      border-bottom: 3px solid #3273fa;
    }
  }
}
