.popular-pros {
  margin-top: 60px;
  @media (max-width: 630px) {
    margin-top: 24px;
    width: 100%;
  }

  .content {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 18px 12px;

    @media (max-width: 1230px) {
      grid-template-columns: repeat(3, auto);
    }
    @media (max-width: 630px) {
      padding-bottom: 12px;
      grid-auto-flow: column;
      grid-template-columns: auto;
      overflow-x: auto;
    }
  }

  .landing_pro {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 190px;
    height: 232px;

    &:hover {
      .headshot {
        top: -4px;
      }
      .champion-name {
        opacity: 0.8;
      }
    }

    .pro-info-box {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 95px;
      padding: 52px 0 12px 0;
      border-radius: 4px 4px 0 0;
      background-color: #1d1d39;
      box-sizing: border-box;

      &:hover {
        .headshot {
          top: -46px;
        }
        .pro-name {
          color: #bec3e1;
          // text-decoration: underline;
          // opacity: 0.8;
        }
      }

      .headshot {
        position: absolute;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -42px;
        // margin: 0 auto -42px;
        width: 72px;
        height: 72px;
        border-radius: 50%;
        border: 6px solid #070720;
        background-color: #43435d;
        transition: top 0.2s;
        overflow: hidden;

        .headshot-img {
          width: 76px;
          flex: 0 0 76px;
        }
      }

      .pro-info {
        display: flex;
        align-items: center;

        .league {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: #111129;
          .league-icon {
            width: 24px;
          }
        }
        .pro-name {
          font-family: "Inter";
          font-size: 16px;
          font-weight: 800;
          text-transform: uppercase;
        }

        .team {
          display: flex;
          align-content: center;
          .custom-image-container {
            @include flex-center;
            .team-logo {
              margin-left: -2px;
              width: 16px;
              height: 16px;
              flex: 0 0 16px;
            }
          }
          .team-name {
            margin-left: 4px;
            max-width: 110px;
            color: #bec3e1;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .pro-champions {
      display: flex;
      flex-direction: column;
      padding: 12px 28px;
      border-radius: 0 0 4px 4px;
      background-color: #111129;

      .header {
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: 500;
      }
      .champions {
        display: grid;
        grid-template-rows: 36px;
        grid-template-columns: repeat(3, min-content);
        grid-gap: 12px;
        min-height: 36px;

        .champion-img {
          width: 36px;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
