@mixin search-bar($options: ()) {
  $conf: map-merge(
    (
      type: "base",
      max-width: 800px,
      height: 60px,
      icon-width: 24px,
      font-size: 18px,
    ),
    $options
  );

  .search-bar_#{map-get($conf, type)} {
    position: relative;
    z-index: 999;
    display: flex;
    max-width: map-get($conf, max-width);
    width: 100%;
    height: map-get($conf, height);
    box-sizing: border-box;

    &.search-bar_placeholder {
      max-width: map-get($conf, max-width);
      width: 100%;
      height: map-get($conf, height);
      border-radius: calc(map-get($conf, height) / 2);
      background-color: #ffffff;
    }

    .search-icon {
      position: absolute;
      align-self: center;
      left: 24px;
      width: map-get($conf, icon-width);
      fill: #626696;

      @include responsive("MOBILE_SMALL") {
        left: 12px;
      }
    }

    .react-autosuggest__container {
      width: 100%;
      flex: 1;

      &.react-autosuggest__container--open {
        .react-autosuggest__input {
          border-top-left-radius: calc(map-get($conf, height) / 2);
          border-top-right-radius: calc(map-get($conf, height) / 2);
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .react-autosuggest__input {
        padding: 0;
        padding-left: map-get($conf, icon-width) + 48px;
        width: 100%;
        height: 100%;
        border-radius: calc(map-get($conf, height) / 2);
        border: none;
        box-sizing: border-box;
        font-size: map-get($conf, font-size);
        color-scheme: none;
        color: black;

        &:focus {
          outline: none;
        }

        @include responsive("MOBILE_SMALL") {
          padding-left: map-get($conf, icon-width) + 24px;
        }
      }

      .react-autosuggest__suggestions-container {
        background-color: #ffffff;
        border-bottom-left-radius: calc(map-get($conf, height) / 2);
        border-bottom-right-radius: calc(map-get($conf, height) / 2);
        overflow: hidden;

        &.react-autosuggest__suggestions-container--open {
          border-top: 1px solid #e3e3eb;
        }

        .react-autosuggest__section-container {
          padding: 12px 0 6px;

          &:not(:last-child) {
            border-bottom: 1px solid #dde0f0;
          }

          .react-autosuggest__suggestions-list {
            margin: 0;
            padding: 0;
            list-style: none;

            .react-autosuggest__suggestion--highlighted {
              background-color: #e3e3eb;
            }
          }

          .react-autosuggest__section-title {
            margin-bottom: 6px;
            margin-left: 24px;
            color: #454566;
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }

    // Suggestion Dropdown
    .suggestion {
      display: flex;
      align-items: center;
      padding: 6px 24px;

      &:hover {
        cursor: pointer;
      }

      .suggestion__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 6px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        overflow: hidden;
      }

      .suggestion__text {
        color: #454566;
        font-size: 14px;
        font-weight: 500;
      }

      &.suggestion_champion {
        .champion-image {
          position: relative;

          @include champion-image-scale;
        }
      }

      &.suggestion_pro {
        .headshot {
          background-color: #454566;

          img {
            max-width: none;
            width: 30px;
          }
        }

        .team-logo {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 4px;
          width: 16px;
          height: 16px;
          flex: 0 0 16px;
          background-color: rgba(#454566, 0.5);
          border-radius: 50%;
          padding: 1px;
          .custom-image-container {
            height: 100%;
            img {
              max-width: none;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

// Base style / Landing Page
@include search-bar();

// Mobile size
@media (max-width: 640px) {
  @include search-bar(
    (
      type: "MOBILE_SMALL",
      height: 40px,
      icon-width: 16px,
      font-size: 16px,
    )
  );
}

// Masthead
@include search-bar(
  (
    type: "masthead",
    max-width: 600px,
    height: 36px,
    icon-width: 16px,
    font-size: 13px,
  )
);
