.error-404-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: $masthead-height auto 0;
  padding-top: 48px;
  @include page-width();

  @include responsive("DESKTOP_SMALL") {
    @include page-width("DESKTOP_SMALL");
  }
  @include responsive("TABLET") {
    @include page-width("TABLET");
  }
  @include responsive("MOBILE_LARGE") {
    @include page-width("MOBILE_LARGE");
    align-items: center;
  }
  @include responsive("MOBILE_SMALL") {
    @include page-width("MOBILE_SMALL");
  }

  .error_left {
    flex: 0 0 45%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    white-space: nowrap;

    h1 {
      font-size: 48px;
      font-weight: 700;
      text-transform: uppercase;
      @include responsive("DESKTOP_SMALL") {
        font-size: 40px;
      }
      @include responsive("MOBILE_SMALL") {
        font-size: 36px;
      }
    }
    span {
      margin: 36px 0;
      color: #bec3e1;
      font-size: 24px;
      font-weight: 400;
      @include responsive("DESKTOP_SMALL") {
        font-size: 20px;
      }
      @include responsive("MOBILE_SMALL") {
        font-size: 16px;
      }
    }
    strong {
      padding-top: 36px;
      border-top: 1px solid #43435d;
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      @include responsive("DESKTOP_SMALL") {
        font-size: 20px;
      }
      @include responsive("MOBILE_SMALL") {
        font-size: 16px;
      }
    }
  }
  .error_right {
    flex: 0 0 50%;
    position: relative;
    @include flex-center;
    @include responsive("MOBILE_LARGE") {
      display: none;
    }

    .pbs-logo {
      width: 240px;
      @include responsive("DESKTOP_SMALL") {
        width: 170px;
      }
    }
    .brush-stroke {
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 608px;
      width: 100%;
    }
  }
}
