.pro-page_side-column {
  // position: sticky;
  // height: min-content;
  // top: 72px;
  grid-column: 2 / 3;
  grid-gap: 0 24px;
  // margin-top: 36px;

  @include responsive("DESKTOP_SMALL") {
    grid-area: 1 / 1 / 2 / 2;
    display: none;
    grid-gap: 0 24px;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 24px;
    padding-bottom: 24px;
    //border-bottom: 1px solid #454566;
  }
  @include responsive("MOBILE_SMALL") {
    grid-area: 1 / 1 / 2 / 2;
    display: none;
    flex-direction: column-reverse;
  }
  .side-column_title {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 700;
    span {
      color: #bec3e1;
      font-weight: 400;
    }
  }

  .side-column_grid-item {
    border-radius: 4px;
    background-color: #1d1d39;
  }

  .pro-general-stats {
    @include responsive("DESKTOP_SMALL") {
      grid-column: 1 / 2;
    }

    .stats {
      padding: 24px;

      strong {
        font-size: 14px;
      }
      span {
        font-size: 12px;
        color: #bec3e1;
      }
    }

    .win-rate {
      @include flex-center;
      flex-direction: column;

      .win-rate-bar {
        width: 100%;
        height: 12px;
        border-radius: 2px;
        background-color: #111129;

        .win-rate-percent {
          height: 100%;
          border-radius: 2px;
          background-color: #3273fa;
        }
      }
      strong {
        margin: 12px 0 4px;
      }
    }

    .stats-ranking {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 18px;
      margin-bottom: -12px;
      padding-top: 18px;
      border-top: 1px solid #454566;

      .ranking {
        @include flex-center;
        flex-direction: column;
        flex: 0 0 45%;
        margin-bottom: 12px;

        @include responsive("DESKTOP_SMALL") {
          flex: 1;
        }
        @include responsive("MOBILE_LARGE") {
          flex: 0 0 45%;
        }

        .rank {
          margin-bottom: 4px;
          font-size: 20px;
          font-weight: 700;
        }
      }
    }

    .most-played-champions {
      @include flex-center;
      flex-direction: column;
      margin-top: 18px;
      padding-top: 18px;
      border-top: 1px solid #454566;

      .title {
        margin-bottom: 18px;
        text-align: center;
      }

      .champion {
        display: flex;
        align-items: center;
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 12px;
        }

        &:hover {
          .champion-name {
            text-decoration: underline;
          }
        }

        .champion-image {
          position: relative;
          margin-right: 12px;
          flex: 0 0 34px;
          width: 34px;
          height: 34px;
          border-radius: 50%;
          overflow: hidden;
          @include champion-image-scale;
        }

        > div {
          display: flex;
          flex-direction: column;
          strong {
            margin-bottom: 2px;
          }
        }

        > div:nth-child(2) {
          flex: 1;
        }
        > div:nth-child(3) {
          flex: 1;
          text-align: right;
        }
      }
    }
  }

  .side-column_pro-achievements {
    grid-column: 2 / 3;
    display: flex;
    flex-direction: column;

    @include responsive("MOBILE_SMALL") {
      margin-bottom: 24px;
    }

    .pro-achievements {
      flex: 1;

      .achievement {
        padding: 12px 12px;

        &:last-child {
          padding-bottom: 0;
        }
        &:not(:last-child) {
          border-bottom: 1px solid #454566;
        }
      }
    }
  }
}
