.match-summary-container {
  position: relative;
  margin-left: -12px;
  padding-left: 12px;
  border-radius: 0 4px 4px 0;
  //width: 100%;
  &:hover {
    .trash-btn {
      @include flex-center;
    }
  }
  .trash-btn {
    display: none;
    position: absolute;
    left: -12px;
    top: 20px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 2px solid #070720;
    background-color: #333354;

    .trash-icon {
      width: 8px;
    }

    &:hover {
      cursor: pointer;
      .trash-icon {
        path {
          fill: #f8b218;
        }
      }
    }
  }

  .match-summary-world {
    position: relative;
    display: grid;
    grid-template-columns: 9% 25% 3% 6% 10% 6% 9% 26% 4%;
    grid-auto-flow: column;
    //padding: 12px 0;
    border-radius: 0 4px 4px 0;
    overflow: hidden;

    &:hover {
      cursor: pointer;
      //background-color: lighten($match-bg-color, 5%);

      &.even {
        //background-color: lighten($match-bg-colo-alt, 5%);
      }

      .grid-item-btn {
        .dropdown-btn {
          //border: 1px solid #ffffff;
        }
      }
    }

    .worlds-bg {
      position: absolute;
      left: -175px;
      width: 500px;
      top: -10px;

      @include responsive("MOBILE_SMALL") {
        left: -100px;
        width: 350px;
        top: -5px;
      }
    }

    .grid-item {
      position: relative;
      padding: 12px 0;
      /*&:not(:first-child) {
          border-left: 1px solid #454566;
        }*/
    }

    .grid-item-player-info {
      @include flex-center;
      // padding: 0 6px 0 12px;

      .player-headshot {
        position: relative;

        .headshot {
          @include flex-center;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: #454566;
          overflow: hidden;

          img {
            width: 40px;
            flex: 0 0 40px;
          }

          &.has-tag {
            border: 2px solid #f8b218;
          }
        }

        .tag {
          @include flex-center;
          position: absolute;
          left: 50%;
          bottom: -5px;
          padding: 3px;
          height: 11px;
          border-radius: 2px;
          background-color: #454566;
          transform: translateX(-50%);
          color: #ffffff;
          font-size: 8px;
          font-weight: 500;
          white-space: nowrap;
        }
      }

      .player-info {
        margin-left: 20px;
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        .name {
          margin-bottom: 2px;
          font-family: "Inter";
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;

          &:hover {
            text-decoration: underline;
          }
        }
        .team {
          display: flex;
          align-items: center;
          .custom-image-container {
            @include flex-center;
            .region-img {
              margin-left: -1px;
              //margin-right: 2px;
              padding-right: 6px;
              border-right: 1px solid #454566;
              max-width: 27px;
              min-width: 27px;
              height: 10px;
              flex: 0 0 10px;
            }
            .team-logo {
              margin-left: 6px;
              margin-right: 4px;
              max-width: 14px;
              height: 14px;
              flex: 0 0 14px;
            }
            .team-logo-pro {
              //margin-left: 6px;
              margin-right: 4px;
              max-width: 14px;
              height: 14px;
              flex: 0 0 14px;
            }
          }
          .team-name {
            flex: 1;
            color: #bec3e1;
            font-size: 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      .champion-image {
        position: relative;
        flex: 0 0 30px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        @include champion-image-scale;
      }

      .league-image {
        width: 30px;
        flex: 0 0 30px;
      }
    }

    .grid-item_2 {
      @include flex-center;

      svg {
        width: 24px;
        height: 24px;

        .roleBg {
          fill: #454566;
        }
      }
    }

    .grid-item-kda {
      @include flex-center;
      flex-direction: column;

      .kda {
        @include flex-center;
        margin-bottom: 2px;
        font-size: 14px;
        font-weight: 400;
        .backslash {
          margin: 0 3px;
        }
      }
      .kda-ratio {
        @include flex-center;
        font-weight: 400;
        font-size: 12px;

        span {
          margin-left: 3px;
        }
      }
    }

    .grid-item-champion-items,
    .grid-item-spells,
    .grid-item-runes {
      display: flex;
      align-items: center;

      .runes {
        position: relative;
        width: 30px;
        height: 30px;

        .keystone {
          @include flex-center;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: #070720;

          > img {
            width: 30px;
            border-radius: 50%;
          }
        }

        .secondary-tree {
          @include flex-center;
          position: absolute;
          right: -8px;
          bottom: 0px;
          width: 17px;
          height: 17px;
          border-radius: 50%;
          background-color: #070720;

          > img {
            width: 15px;
          }
        }
      }

      .summoner-spells {
        display: flex;
        align-items: center;
        //margin-left: 35px;

        .spell-image {
          width: 30px;
          height: 30px;
          @include responsive("TABLET") {
            width: 24px;
            height: 24px;
          }
          border-radius: 2px;
          border: 1px solid #454566;

          &:first-child {
            margin-right: 6px;
          }

          > img {
            width: 100%;
            border-radius: 2px;
          }
        }
      }

      .items {
        position: relative;
        display: flex;
        align-items: center;
        //margin-left: 24px;

        .item-image {
          width: 30px;
          height: 30px;
          @include responsive("TABLET") {
            width: 24px;
            height: 24px;
          }
          border-radius: 2px;
          border: 1px solid #454566;
          background-color: #17172e;

          &:not(last-child) {
            margin-right: 6px;
          }
          &.trinket {
            margin-right: 0;
          }

          &.completed-item {
            // border: 1px solid #d8d8d8;
            border: 1px solid $completed-item-border-color;
          }
          &.mythic-item {
            // padding: 1px;
            border: 1px solid $mythic-item-border-color;
            // background: linear-gradient(to bottom right, #F8B218, #7C5501);
          }

          > img {
            width: 100%;
            height: 100%;
            border: 1px solid $inner-item-border-color;
            // border-radius: 2px;
          }
        }

        .item-sort {
          position: absolute;
          left: 5px;
          top: calc(100% + 2px);
          display: flex;
          padding: 2px;
          border-radius: 2px;
          background-color: #1d1d39;

          &.even {
            background-color: #111129;
          }

          &.full-build,
          &.unique-build {
            right: 5px;
          }

          &.full-build {
            .item-build-label {
              color: #bec3e1;
            }
            svg path {
              fill: #bec3e1;
            }
          }
          &.unique-build {
            .item-build-label {
              color: #f8b218;
            }
            svg path {
              fill: #f8b218;
            }
          }

          svg {
            width: 11px;
            height: 5px;
          }

          .item-build-label {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: "Inter";
            font-size: 8px;
            font-style: normal;
            letter-spacing: 0.8px;
            text-transform: uppercase;
          }
        }
      }
    }

    .grid-item-champion-image,
    .grid-item-champion-opp-image-champ_page,
    .grid-item-opp-image {
      @include flex-center;
      justify-content: unset;
      color: #ffffff;
      font-size: 8px;
      font-weight: 500;
      text-transform: uppercase;

      .champion-image {
        position: relative;
        //margin-left: 6px;
        width: 28px;
        height: 28px;
        @include responsive("TABLET") {
          width: 24px;
          height: 24px;
        }
        border-radius: 50%;
        overflow: hidden;
        @include champion-image-scale;
      }

      .vs-text {
        //justify-content: flex-end;
        padding-left: 8px;
        width: 30px;
        height: 12px;
        font-size: 8px;
      }
    }
    .grid-item-champion-opp-image-champ_page {
      justify-content: flex-end;
    }
    .grid-item-time-stamp {
      padding-bottom: 4px;
      padding-top: 4px;
      @include flex-center;
      .match-date {
        .world-logo {
          width: 30px;
          height: 30px;
          margin-bottom: 4px;
          @include responsive("MOBILE_SMALL") {
            width: 17px;
            height: 17px;
          }
        }
        @include flex-center;
        flex-direction: column;
        padding-bottom: 0px;
        //padding-right: 12px;
        &.recommended {
          color: #ffffff;
          // .timestamp {
          //   display: none;
          // }
          .patch {
            color: #ffffff;
            &.latest-patch {
              color: #f8b218;
            }
          }
        }
        .timestamp {
          width: 70px;
          height: 18px;
          border-radius: 5px;
          background-color: #111129;
          opacity: 0.4;
          text-align: center;
          //margin-bottom: 5px;
          color: #bec3e1;
          font-size: 12px;
          @include responsive("MOBILE_SMALL") {
            width: 37px;
            height: 13px;
            font-size: 9px;
          }
          font-weight: 400;
          white-space: nowrap;
        }
        .patch {
          color: #bec3e1;
          font-size: 12px;
          font-weight: 400;
          text-transform: uppercase;
        }
      }
    }

    .grid-item-btn {
      @include flex-center;

      &:hover {
        cursor: pointer;
        .dropdown-btn {
          // border: 1px solid #3273fa;
          //border: 1px solid #ffffff;
          svg path {
            // fill: #3273fa;
          }
        }
      }

      .dropdown-btn {
        @include flex-center;
        width: 18px;
        height: 18px;
        padding-left: 12px;
        border-radius: 50%;
        //border: 1px solid #454566;

        /*&.dropdown-btn_reverse {
            transform: rotateX(180deg);
          }*/

        svg {
          height: 4px;
          width: 18px;
        }
      }
    }

    @include responsive("TABLET") {
      grid-template-columns: 9% 20% 3% 7% 12% 7% 9% 26% 5%;
    }

    @include responsive("MOBILE_LARGE") {
      grid-template-columns: 13% 26% 12% 9% 30%;
      .grid-item-champion-image,
      .grid-item-opp-image,
      .grid-item-opp-image-champ_page,
      .grid-item-spells {
        display: none;
      }
    }

    @include responsive("MOBILE_SMALL") {
      grid-template-rows: auto auto;
      grid-template-columns: 13% 15% 10% 10% 5% 20% 7% 5% 5%;
      //grid-gap: 12px 0;
      grid-template-areas:
        "info info info info info kda champimg opp opp ."
        "time rune item item item item item item btn btn";
      .grid-item-champion-image {
        display: flex;
      }
      .grid-item-opp-image,
      .grid-item-opp-image-champ_page {
        display: flex;
      }
      .grid-item-player-info {
        .player-info {
          margin-left: 10px;
        }
        .player-headshot {
          margin-left: 5px;
        }
        grid-area: info;
      }
      .grid-item-champion-image {
        grid-area: champimg;
        border-right: none;
      }
      .grid-item-opp-image .grid-item-opp-image-champ_page {
        grid-area: opp;
        border-right: none;
      }
      .grid-item-kda {
        grid-area: kda;
        border-right: none;
      }
      .grid-item-champion-items {
        grid-area: item;
        border-left: none !important;
        .items {
          .item-image {
            &:not(last-child) {
              margin-right: 3px;
            }
          }
        }
      }
      .grid-item-runes {
        grid-area: rune;
        border-left: none !important;
      }
      .grid-item-btn {
        grid-area: btn;
        border-right: none;
      }
    }
  }
}
