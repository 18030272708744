.top-picks-page {
  @include responsive("DESKTOP_SMALL") {
    width: 100%;
  }

  &_content {
    display: grid;
    grid-template-columns: 370px 1fr;
    grid-gap: 24px;
    min-height: 500px;

    @include responsive("TABLET") {
      grid-template-columns: 1fr;
      grid-auto-flow: row;
    }
  }

  .spinner {
    grid-column: 1 / 3;
    margin: 24px auto 0;
    width: 50px;
  }

  .error-section {
    grid-column: 1 / 3;
  }

  .top-picks-table {
    flex: 0 0 370px;
    border-radius: 4px;
    background-color: #1d1d39;

    @include responsive("MOBILE_LARGE") {
      flex: 1;
      grid-row: 2 / 3;
    }
    @include responsive("MOBILE_SMALL") {
      grid-row: 1 / 2;
    }

    .rt_table {
      max-height: 662px;
      padding: 0 24px;
      overflow: auto;

      @include responsive("MOBILE_LARGE") {
        padding: 0 12px;
      }
      @include responsive("MOBILE_SMALL") {
        padding: 0 8px;
      }

      .rt_header {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #1d1d39;

        .rt_header__row {
          display: flex;

          .rt_header__cell {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 18px;
            color: #bec3e1;
            font-size: 12px;

            .header-container {
              position: relative;
              padding: 6px;
              border-top: 1px solid transparent;
              border-bottom: 1px solid transparent;
            }

            &.is-sorted {
              background-color: rgba(51, 51, 84, 0.5);
              color: #ffffff;
              font-weight: 700;

              &.pickrate-header {
                &.sort-asc {
                  .header-container {
                    border-top: 1px solid #f8b218;
                  }
                }
                &.sort-desc {
                  .header-container {
                    border-bottom: 1px solid #f8b218;
                  }
                }
              }
              &.winrate-header {
                &.sort-asc {
                  .header-container {
                    border-top: 1px solid #3273fa;
                  }
                }
                &.sort-desc {
                  .header-container {
                    border-bottom: 1px solid #3273fa;
                  }
                }
              }
            }
          }
        }
      }
      .rt_body {
        .rt_body__row {
          display: flex;
          &:not(:last-child) {
            border-bottom: 1px solid #454566;
          }
          &:first-child {
            .rt_body__cell {
              padding-top: 7px;
            }
          }
          .rt_body__cell {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px 0;
            color: #ffffff;
            font-size: 14px;
            font-weight: 700;

            &.is-sorted {
              background-color: rgba(51, 51, 84, 0.5);
            }
            &.champion {
              justify-content: flex-start;
            }
          }
        }
      }
    }

    .champion-column {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-right: 12px;
      overflow: hidden;

      &:hover {
        .champion-info {
          .champion-name {
            span {
              text-decoration: underline;
            }
          }
        }
      }

      .champion-image-container {
        position: relative;
        flex: 0 0 34px;
        margin-right: 12px;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        overflow: hidden;
        @include champion-image-scale;
      }

      .champion-info {
        flex: 1;
        overflow: hidden;

        .champion-name {
          display: flex;
          align-items: center;
          line-height: 14px;

          svg {
            margin-right: 6px;
            width: 10px;
          }
          span {
            font-family: "eurostile";
            color: #ffffff;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .rate-bar {
          margin-top: 8px;
          width: 100%;
          height: 6px;
          border-radius: 3px;
          background-color: #111129;

          .bar-percent {
            height: 6px;
            border-radius: 3px;
          }
        }
      }
    }
  }

  .top-picks-chart {
    flex: 1;
    // height: 662px;
    border-radius: 4px;
    background-image: linear-gradient(225deg, #1d1d39 0%, #111129 100%);

    @include responsive("TABLET") {
      grid-row: 1 / 2;
    }

    .highcharts-container {
      width: 100% !important;
      height: 100% !important;

      svg.highcharts-root {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .highcharts-label.highcharts-data-label {
      opacity: 1 !important;
      &:hover {
        z-index: 9999999;
      }
    }
    .highcharts-markers {
      display: none;
    }
    .highcharts-root {
      .highcharts-tooltip {
        display: none;
      }
    }
    .highcharts-tooltip {
      z-index: 999;
      .plot-tooltip {
        padding: 5px 8px;
        border-radius: 3px;
        background-color: #0b0b23;
        color: #ffffff;

        .champion-name {
          margin-bottom: 4px;
          font-family: "Roboto";
          color: #ffffff;
          font-size: 14px;
          font-weight: 700;
        }
        .label {
          font-family: "Roboto";
          color: #bec3e1;
          font-size: 12px;
          font-weight: 400;
        }
        .value {
          font-family: "Roboto";
          color: #ffffff;
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
    .champion-plot-image {
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 3px solid #333354;
      background-color: #070720;

      @include responsive("TABLET") {
        width: 34px;
        height: 34px;
        border: 2px solid #333354;
      }

      &:hover {
        cursor: pointer;
      }

      .champion-image {
        @include champion-image-scale;
        width: 100%;
        height: 100%;
        border: 1px solid #070720;
        border-radius: 50%;
        overflow: hidden;
      }

      .role {
        position: absolute;
        right: -3px;
        bottom: -3px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 14px;
        height: 14px;
        border-radius: 1px;
        background-color: #333354;

        img {
          width: 10px;
        }
      }
    }
  }
}
