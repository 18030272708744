.champion-home-page {
  .champion-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(104px, 1fr));
    grid-gap: 22px;
    justify-content: center;
    padding: 22px;
    border-radius: 4px;
    background-color: #111129;

    @include responsive("MOBILE_SMALL") {
      grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
      grid-gap: 16px;
      padding: 12px;
    }

    .champion-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 12px 12px 6px 12px;
      border-radius: 4px;
      background-color: #1d1d39;

      &:hover {
        background-color: #333354;
      }

      .champion-image {
        flex: 0 0 80px;
        width: 80px;
        height: 80px;

        @include responsive("MOBILE_SMALL") {
          flex: 0 0 60px;
          width: 60px;
          height: 60px;
        }
      }

      .champion-name {
        margin-top: 6px;
        width: 100%;
        color: #bec3e1;
        font-size: 14px;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        @include responsive("MOBILE_SMALL") {
          font-size: 12px;
        }
      }
    }
  }
}
