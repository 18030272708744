.custom-checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;

  &:not(.inactive):hover {
    cursor: pointer;
  }

  input {
    position: absolute;
    margin: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
  }

  .styled-checkbox {
    @include flex-center;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: #333354;

    &:hover {
      background-color: rgba(#3273fa, 0.6);
      cursor: pointer;
    }

    svg {
      width: 10px;
      visibility: hidden;
    }
  }

  &.inactive .styled-checkbox {
    pointer-events: none;
  }

  input:checked ~ .styled-checkbox {
    svg {
      visibility: visible;
    }
  }

  &:not(.inactive):hover .styled-checkbox,
  input:focus ~ .styled-checkbox,
  .styled-checkbox:focus {
    box-shadow: 0 0 3px 1px rgba(#3273fa, 0.6);
    background-color: rgba(#3273fa, 0.6);
    cursor: pointer;
  }

  &.inactive {
    .styled-checkbox {
      opacity: 1 !important;
      cursor: default;
      &:focus {
        box-shadow: none;
      }
    }
  }
}
