@tailwind base;
@tailwind components;
@tailwind utilities;

// Variables
@import "variables";

// Fonts
@import "fonts/eurostile";

// Responsive
@import "~@outplayed/responsive/index.scss";
@import "~@outplayed/tooltips/index.scss";
@import "responsive";

@import "app";
@import "common-page";
@import "components/common/Ads/ads";
@import "components/MobileMenu/mobile-menu";
@import "components/Masthead/masthead";
@import "components/MainContent/main-content";
@import "components/Footer/footer";
@import "components/Error404/error-404";
@import "components/SearchBar/search-bar";
@import "components/Filters/filters";
@import "components/common/PageNav/page-nav";
@import "components/common/Checkbox/checkbox";
@import "components/common/Error/common-error";

@import "components/Settings/settings";
@import "components/Settings/pages/Trashcan/settings-trashcan";

@import "components/LandingPage/styles/landing-page";
@import "components/ChampionHomePage/champion-home-page";
@import "components/Champions/styles/champion-page";
@import "components/ProHomePage/pro-home-page";
@import "components/Pros/styles/pro-page";
@import "components/common/MatchCard/match-card";
@import "components/TopPicks/top-picks";

@import "components/common/RuneTree/rune-tree";
@import "components/common/ItemTimeline/item-timeline";
@import "components/common/SkillPath/skill-path";
