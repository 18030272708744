.skill-path {
  .skill-order-row {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    .skill-img {
      position: relative;
      display: flex;
      align-items: center;
      margin-right: 4px;

      &:not(:last-child) {
      }

      img {
        width: 24px;
      }
      .label {
        @include flex-center;
        position: absolute;
        bottom: -3px;
        right: -3px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #0b0b23;
        opacity: 0.8;
        font-size: 8px;
        font-weight: 500;
      }
    }
    .skill-order {
      display: flex;
      align-items: center;
      flex: 1;

      .skill-slot {
        @include flex-center;
        flex: 1;
        min-width: 24px;
        max-width: 30px;
        height: 24px;
        background-color: #111129;
        font-size: 10px;
        font-weight: 500;

        &:not(:last-child) {
          margin-right: 4px;
        }

        &.level-up {
          background-color: #3273fa !important;
        }
      }
    }
  }
}
