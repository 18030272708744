#masthead {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999999;
  height: $masthead-height;
  flex: 0 0 $masthead-height;
  border-bottom: 1px solid #31334a;
  background-color: #0b0b23;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;

  @include responsive("MOBILE_LARGE") {
    padding: 0 18px 0 12px;
  }

  &.home:not(.menu-open) {
    position: static;
    border-color: transparent;
    background-color: transparent;
  }

  .masthead_icon {
    @include flex-center;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    &:hover {
      background-color: #222238;
    }
  }

  .menu-button {
    @include flex-center;
    margin-right: 12px;
    width: 30px;
    height: 30px;

    .menu-icon {
      width: 16px;
    }
  }

  .masthead_link {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;

    &:hover {
      color: #bec3e1;
    }

    &:not(:last-child) {
      margin-right: 30px;
    }

    &.active {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        bottom: -4px;
        width: 100%;
        height: 2px;
        border-radius: 1px;
        background-color: #3273fa;
      }
    }
  }

  .masthead_left {
    flex: 1;
    display: flex;
    align-items: center;
    margin-right: 24px;
    @include responsive("MOBILE_LARGE") {
      flex: none;
    }

    .masthead_logo {
      display: flex;
      svg {
        width: 24px;
      }
    }
    .vertical-divider {
      margin: 0 30px;
      width: 1px;
      height: 30px;
      background-color: #333354;
    }
    .masthead_links {
      display: flex;
      align-items: center;
      white-space: nowrap;

      .new-tag {
        margin-left: 6px;
        margin-bottom: -1px;
        width: 30px;
      }
      .world-logo {
        margin-bottom: -3px;
        margin-right: 4px;

        width: 17px;
        height: 17px;
      }
    }
  }
  .masthead_center {
    flex: 2;
    @include flex-center;
  }
  .masthead_right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-left: 24px;
    @include responsive("TABLET") {
      flex: none;
    }

    .cog-icon {
      width: 16px;
      path {
        fill: #ffffff;
      }
    }
  }
}
