.ad-box-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //padding: 0 12px 12px;
  border-radius: 6px;
  background-color: #111129;
}

.ad-box-wrapper,
.ad-placement {
  .ad-box_label,
  .ad-placement_label {
    align-self: flex-start;
    width: 71px;
    height: 12px;
    margin: 6px 0;
    padding: 0 6px;
    color: #454566;
    font-family: "Inter";
    font-size: 10px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
  }
}
