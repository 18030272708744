.champion-page_header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 24px;
  width: 100%;

  .main-header {
    flex: 1;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: min-content auto;

    .champion-image-container {
      grid-area: 1 / 1 / 3 / 2;
      margin-right: 24px;
      padding: 2px;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid #f8b218;

      .champion-image {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
        @include champion-image-scale;
      }

      @include responsive("TABLET") {
        margin-right: 12px;
      }
      @include responsive("MOBILE_LARGE") {
        width: 70px;
        height: 70px;
      }
      @include responsive("MOBILE_SMALL") {
        grid-area: 1 / 1 / 2 / 2;
      }
    }

    .header-title {
      grid-area: 1 / 2 / 2 / 3;
      margin-bottom: 6px;
      color: #ffffff;
      font-family: "Inter";
      font-size: 24px;
      font-weight: 700;
      text-transform: uppercase;

      @include responsive("MOBILE_LARGE") {
        margin-bottom: 0px;
      }
      @include responsive("MOBILE_SMALL") {
        display: flex;
        flex-direction: column;
        grid-area: 1 / 2 / 2 / 3;
      }
    }

    .header-blurb {
      grid-area: 2 / 2 / 3 / 3;
      display: flex;
      flex-direction: column;
      color: #bec3e1;
      font-size: 16px;
      line-height: 24px;

      @include responsive("TABLET") {
        display: inline;
        padding-right: 24px;
        font-size: 13px;
        line-height: 19px;
      }
      @include responsive("MOBILE_SMALL") {
        grid-area: 2 / 1 / 3 / 3;
        display: inline;
        margin-top: 12px;
      }
    }
  }

  .ugg-links {
    display: flex;
    align-self: flex-start;

    a {
      @include flex-center;
      padding: 0 12px 0 17px;
      // width: 190px;
      height: 40px;
      border-radius: 2px;
      border: 2px solid #454566;
      background-color: rgba(#070720, 0.7);

      &:hover {
        border-color: #3273fa;
        background-color: #3273fa;
      }

      .link-text {
        flex: 1;
        font-size: 16px;
        font-weight: 500;
      }
      margin-left: 24px;

      &:first-child {
        margin-left: 0px;
      }
      @include responsive("DESKTOP_SMALL") {
        margin-top: 6px;
        margin-left: 0;

        a:first-child {
          margin-top: 0;
          margin-left: 0;
        }
      }
      @include responsive("MOBILE_LARGE") {
        margin-top: 24px;
        margin-top: 0;
        margin-left: 12px;
        a:first-child {
          margin-left: 0;
        }
      }
    }

    .ugg-logo {
      margin-right: 10px;
      width: 12px;
    }
    .og-logo {
      margin-right: 10px;
      width: 16px;
    }
    .link-icon {
      margin-left: 10px;
      width: 16px;
    }

    @include responsive("DESKTOP_SMALL") {
      flex-direction: column;
    }

    @include responsive("MOBILE_LARGE") {
      flex-direction: row;
      flex: 0 0 100%;
      margin-top: 24px;

      a:last-child {
        margin-top: 0;
        margin-left: 12px;
      }
    }

    @include responsive("MOBILE_SMALL") {
      justify-content: space-between;
      margin-top: 14px;

      a {
        padding: 0 8px;
        width: 164px;
        height: 36px;
        &:last-child {
          margin: 0;
        }

        .link-text {
          font-size: 12px;
        }
        .ugg-logo {
          margin-right: 6px;
          width: 9px;
        }
        .og-logo {
          margin-right: 10px;
          width: 16px;
        }
        .link-icon {
          margin-left: 6px;
          width: 12px;
        }
      }
    }
  }
}
