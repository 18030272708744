.pro-page_header-container {
  display: grid;
  grid-template-rows: auto 60px;
  grid-template-columns: 1fr auto;
  margin-bottom: 24px;
  width: 100%;

  .pro-page_header_achievements {
    grid-area: 1 / 2 / 3 / 3;
    display: flex;
    flex-direction: column;
    @include responsive("MOBILE_SMALL") {
      width: 340px;
    }
    .title {
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 700;
    }
    .pro-achievements {
      flex: 1;
      min-width: 290px;

      .achievement {
        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
  }
  .pro-page_filters {
    grid-area: 2 / 1 / 3 / 2;
    display: flex;
    align-items: flex-end;
  }

  @include responsive("MOBILE_LARGE") {
    //display: flex;
    flex-direction: column;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 24px;
  }
}

.pro-page_header {
  flex: 1;
  display: grid;
  grid-template-rows: 100px 40px;
  grid-template-columns: 130px 1fr;
  grid-gap: 12px 18px;

  .player-headshot {
    grid-area: 1 / 1 / 2 / 2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-radius: 4px;
    background-color: #1d1d39;

    img {
      height: 100px;
    }
  }

  .player-info {
    grid-area: 1 / 2 / 2 / 3;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .player-info_1 {
      display: flex;
      flex-direction: column;
      margin-bottom: 12px;

      .player-name {
        margin-top: 6px;
        font-family: "Inter";
        font-size: 24px;
        font-weight: 900;
        line-height: 1;
        text-transform: uppercase;
      }

      .player-tags {
        position: relative;
        top: 2px;
        display: flex;
        margin: 8px 0 2px;

        .tag {
          @include flex-center;
          position: relative;
          padding: 0 6px;
          height: 20px;
          border-radius: 2px;
          border: 1px solid #454566;
          background-color: #454566;
          font-size: 11px;
          white-space: nowrap;
          overflow: hidden;

          &:not(:last-child) {
            margin-right: 8px;
          }

          &.special-tag {
            border: 1px solid #f8b218;
            background-color: #664a1e;

            .special-tag_background {
              position: absolute;
              left: 0;
            }
          }

          span {
            z-index: 1;
          }
        }
      }
    }
    .player-info_2 {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      > * {
        padding: 0 12px;
        &:not(:last-child) {
          border-right: 1px solid #454566;
        }
      }

      .team {
        display: flex;
        align-items: center;

        img {
          margin-left: -12px;
          margin-right: 6px;
          width: 28px;
          flex: 0 0 24px;
        }
        .team-name {
          font-size: 14px;
          font-weight: 500;
        }
      }
      .league {
        display: flex;
        align-items: center;

        img {
          width: 28px;
        }
      }
      .role {
        @include flex-center;
        > div {
          @include flex-center;
          width: 24px;
          height: 24px;
          background-color: #070720;
          svg {
            width: 16px;
          }
        }
      }
      .country {
        display: flex;
        align-items: center;
        img {
          width: 20px;
        }
      }
    }
  }
  .profile-link {
    grid-area: 2 / 1 / 3 / 2;
    display: flex;
    align-items: center;
    padding: 0 10px 0 16px;
    height: 40px;
    border-radius: 2px;
    background-color: #3273fa;

    &:hover {
      opacity: 0.8;
    }

    .link-text {
      flex: 1;
    }

    .ugg-logo {
      margin-right: 10px;
      width: 12px;
    }
    .link-icon {
      margin-left: 10px;
      width: 16px;
    }
  }

  .player-blurb {
    grid-area: 2 / 2 / 3 / 3;
    display: flex;
    align-items: center;
    max-width: 385px;
    color: #bec3e1;
    font-size: 12px;
    line-height: 18px;
  }

  @include responsive("MOBILE_SMALL") {
    grid-template-rows: 100px 40px auto;

    .player-info {
      grid-area: 1 / 2 / 3 / 3;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      padding-bottom: 6px;

      .player-info_1 {
        flex-direction: column;
        align-items: flex-start;

        .player-tags {
          top: 0;
          margin-left: 0;
          margin-top: 8px;
        }
      }
    }
    .team {
      flex: 0 0 100%;
      margin-bottom: 8px;
      border-right: none !important;
    }
    .league {
      padding-left: 0 !important;
    }
    .player-blurb {
      grid-area: 3 / 1 / 4 / 3;
    }
  }
}
