.champion-page_top-bar {
  border-radius: 4px;
  background-color: #111129;
  display: flex;
  justify-content: center;

  .champion-page_top-bar-stats {
    display: grid;
    grid-template-areas:
      "winrate roles runes skills"
      "items items boots spells";
    padding: 24px 57px;
    height: min-content;
    align-self: center;
    gap: 24px 18px;

    @include responsive("TABLET") {
      row-gap: 18px;
    }

    @include responsive("MOBILE_LARGE") {
      display: grid;
      grid-template-areas:
        "winrate roles runes skills"
        "items items boots spells";
      row-gap: 20px;
      padding: 12px;
    }

    @include responsive("MOBILE_SMALL") {
      padding-top: 12px;
      padding-bottom: 24px;
      display: grid;
      grid-template-areas:
        "winrate roles runes"
        "skills boots spells"
        "items items items";
      row-gap: 14px;
      column-gap: 24px;
    }

    .champion-win-rate {
      grid-area: winrate;

      .win-rate {
        padding-top: 14px;
        font-size: 24px;
        font-weight: 400;

        @include responsive("TABLET") {
          padding-top: 12px;
          font-size: 20px;
        }
      }
      .total-matches {
        margin-top: 6px;
        font-size: 10px;
        font-weight: 500;
        color: #bec3e1;
      }
    }
    .champion-skills {
      grid-area: skills;

      .skill {
        .skill-img {
          position: relative;
          display: flex;
          align-items: center;
          width: 30px;
          height: 30px;
          border: 1px solid #454566;

          @include responsive("TABLET") {
            width: 24px;
            height: 24px;
          }

          img {
            width: 100%;
          }
        }
        .label {
          text-align: center;
          margin-top: 6px;
          font-size: 10px;
          font-weight: 500;
          color: #bec3e1;
        }
      }
      .top-items {
        display: grid;
        padding-top: 12px;
        grid-template-columns: 30px 4px 30px 4px 30px;
        @include responsive("TABLET") {
          grid-template-columns: 24px 6px 24px 6px 24px;
        }
        .arrow-align {
          display: flex;
          margin-top: 37px;

          @include responsive("TABLET") {
            margin-top: 32px;
          }

          .arrow {
            width: 4px;
            height: 6px;
          }
        }

        .pick-rate {
          margin-top: 6px;
          font-size: 10px;
          font-weight: 500;
          color: #bec3e1;
        }
      }
      .top-items {
        display: flex;
        align-items: center;
        padding-top: 12px;

        .item {
          @include flex-center;
          flex-direction: column;
          &:not(:last-child) {
            margin-right: 6px;
          }
          &.do-not-display {
            display: none !important;
          }
          .item-image {
            width: 30px;
            height: 30px;
            background: #111129;
            @include responsive("TABLET") {
              width: 24px;
              height: 24px;
            }

            &.completed-item {
              border: 1px solid $completed-item-border-color;
            }
            &.mythic-item {
              border: 1px solid $mythic-item-border-color;
            }

            img {
              width: 100%;
              border: 1px solid $inner-item-border-color;
            }
          }
          .pick-rate {
            margin-top: 6px;
            font-size: 10px;
            font-weight: 500;
            color: #bec3e1;
          }
        }
      }
    }

    .side-column_title {
      font-size: 12px;
      font-weight: 600;
      white-space: nowrap;
      span {
        color: #bec3e1;
        font-weight: 400;
      }
    }

    .side-column_grid-item {
      border-radius: 4px;
      background-color: #111129;
    }

    .side-column_blurb {
      padding-bottom: 22px;
      border-bottom: 1px solid #43435d;
      font-size: 14px;
      line-height: 21px;
      span {
        color: #bec3e1;
      }
    }

    .champion-roles,
    .champion-runes {
      display: flex;
      flex-direction: column;

      .side-column_title {
        white-space: nowrap;
      }

      .top-roles,
      .top-runes {
        display: flex;
        padding-top: 12px;
        flex: 1;

        .role,
        .runes {
          max-width: 40px;
          flex-direction: column;
          flex: 1;
        }
      }
      .rate {
        padding-left: 6px;
        margin-top: 6px;
        font-size: 10px;
        font-weight: 500;
        color: #bec3e1;
      }
    }

    .champion-roles {
      grid-area: roles;

      .top-roles {
        .role {
          .role-img {
            @include flex-center;
            flex: 1;
            width: 30px;
            height: 30px;

            @include responsive("TABLET") {
              width: 24px;
              height: 24px;
            }
          }
          svg {
            width: 22px;
          }
        }
      }
    }
    .champion-runes {
      grid-area: runes;

      .top-runes {
        .runes {
          max-width: 45px;
          .rune {
            flex: 1;

            .rune-img {
              position: relative;
              width: 30px;
              height: 30px;

              @include responsive("TABLET") {
                width: 24px;
                height: 24px;
              }

              > div {
                @include flex-center;
                border-radius: 50%;
                background-color: #070720;
                > img {
                  border-radius: 50%;
                }
              }

              .keystone {
                width: 30px;
                height: 30px;
                img {
                  width: 30px;
                }
              }
              .secondary-tree {
                position: absolute;
                bottom: 0;
                right: -8px;
                width: 17px;
                height: 17px;

                @include responsive("TABLET") {
                  bottom: -6px;
                }

                img {
                  width: 15px;
                }
              }
            }
          }
        }
      }
    }

    .champion-items {
      grid-area: items;

      .top-items {
        display: flex;
        align-items: center;
        padding-top: 12px;

        .item {
          @include flex-center;
          flex-direction: column;
          &:not(:last-child) {
            margin-right: 6px;
          }
          &.do-not-display {
            display: none !important;
          }
          .item-image {
            width: 30px;
            height: 30px;
            background: #111129;

            @include responsive("TABLET") {
              width: 24px;
              height: 24px;
            }

            &.completed-item {
              border: 1px solid $completed-item-border-color;
            }
            &.mythic-item {
              border: 1px solid $mythic-item-border-color;
            }

            img {
              width: 100%;
              border: 1px solid $inner-item-border-color;
            }
          }
          .pick-rate {
            margin-top: 6px;
            font-size: 10px;
            font-weight: 500;
            color: #bec3e1;
          }
        }
      }
    }

    .champion-boots {
      grid-area: boots;
    }

    .champion-spells {
      grid-area: spells;

      .top-items {
        display: flex;
        align-items: center;
        padding-top: 12px;

        .item {
          @include flex-center;
          flex-direction: column;
          &:not(:last-child) {
            margin-right: 6px;
          }
          &.do-not-display {
            display: none !important;
          }
          .item-image {
            width: 30px;
            height: 30px;
            background: #111129;
            border: 1px solid #454566;

            @include responsive("TABLET") {
              width: 24px;
              height: 24px;
            }

            img {
              width: 100%;
              border: 1px solid $inner-item-border-color;
            }
          }
          .pick-rate {
            margin-top: 6px;
            font-size: 10px;
            font-weight: 500;
            color: #bec3e1;
          }
        }
      }
    }
  }
}
