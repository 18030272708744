$filter-color: #454566;
$filter-hover-color: #333354;
$filter-height: 36px;
$filter-border-radius: 2px;

.filter-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  background-color: #0b0b23;
  font-size: 1.4rem;

  .filter-modal_header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 12px;
    height: 50px;
    flex: 0 0 50px;
    border-bottom: 1px solid #43435d;
    font-size: 18px;

    .filter-modal_close {
      position: absolute;
      top: 50%;
      right: 12px;
      padding: 8px;
      transform: translateY(-50%);

      svg {
        width: 16px;

        g,
        path {
          fill: #ffffff;
        }
      }
    }
  }

  .filter-modal_content {
    flex: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .filter-modal_buttons {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 0 0 90px;
    padding: 0 12px 29px;
    background-color: #0b0b23;
    box-shadow: 0;
    transition: box-shadow 0.3s;

    &.fade-content {
      box-shadow: 0px -25px 40px 10px #0b0b23;
    }

    .filter-modal_cancel,
    .filter-modal_apply {
      @include flex-center;
      padding: 10px 12px;
      min-width: 100px;
      border-radius: 4px;
      border: 1px solid #43435d;
      font-size: 16px;
    }

    .filter-modal_cancel {
      border: 1px solid #43435d;
    }
    .filter-modal_apply {
      margin-left: 12px;
      background-color: #3273fa;
    }
  }

  .filter-modal_options {
  }

  .filter-modal_radio-buttons {
    display: flex;
    align-items: center;
    padding: 0 12px;
    height: 50px;

    &:not(:last-child) {
      border-bottom: 1px solid #43435d;
    }

    label {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 35px;
      width: 100%;
      height: 100%;
      font-size: 14px;
      cursor: pointer;
    }

    .radio-button {
      @include flex-center;
      position: absolute;
      left: 0;
      top: 50%;
      width: 24px;
      height: 24px;
      border: 2px solid #ffffff;
      border-radius: 50%;
      transform: translateY(-50%);

      &:after {
        display: block;
        opacity: 0;
        content: "";
        transform: scale(0);
        transition: opacity 0.2s, transform 0.2s;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    input:checked ~ .radio-button:after {
      width: 12px;
      height: 12px;
      opacity: 1;
      border-radius: 50%;
      background-color: #ffffff;
      transform: scale(1);
    }
  }
}

.filter-manager {
  position: sticky;
  z-index: 98;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $filter-height;
  box-sizing: content-box;

  @include responsive("MOBILE_SMALL") {
    margin: 12px 0;
    width: 100%;
  }

  .filter-manager_scroll-wrapper {
    display: flex;
    align-items: center;
    margin-right: auto !important;
    -webkit-overflow-scrolling: touch;

    @include responsive("MOBILE_SMALL") {
      position: absolute;
      overflow-x: auto;
      left: -12px;
      right: -12px;
      margin-bottom: -12px;
      padding-bottom: 12px;
    }

    .filter-manager_main {
      display: flex;
      align-items: center;

      @include responsive("MOBILE_SMALL") {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }
}

.filter-select-wrapper,
.filter-select {
  position: relative;

  &:not(:last-child) {
    margin-right: 12px;
  }

  .filter-select__control {
    @include flex-center;
    //justify-content: left;
    padding: 0 12px;
    height: $filter-height;
    border-radius: $filter-border-radius;
    background-color: $filter-color;

    &:hover {
      background-color: $filter-hover-color;
      cursor: pointer;
    }
  }

  .filter-select__value-container {
    @include flex-center;
    font-size: 13px;
    font-weight: 700;
    white-space: nowrap;
  }

  .filter-select__option {
    padding: 8px 12px;
    font-size: 13px;

    &:hover {
      background-color: $filter-color;
    }
  }

  .filter-select__indicators {
    display: none;
  }

  .filter-select__menu {
    border-radius: $filter-border-radius;
    box-shadow: 0 2px 4px rgba(7, 7, 32, 0.74);
    //border-radius: 2px;
    background-color: #333354;
  }
}

.filter_role {
  .role-value {
    display: flex;
    align-items: center;

    svg {
      width: 14px;
    }
    .arr-svg {
      margin-left: 6px;
      width: 7px;
      flex: 0 0 7px;
    }
  }
}

.filter_role-split {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0px 4px;
  background-color: #070720;

  .role-option {
    @include flex-center;
    width: 95px;
    height: 36px;
    .role-label {
      //width: 47px;
      height: 15px;
      color: #bec3e1;
      font-family: "Inter";
      font-size: 12px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
      text-align: left;
    }
    @include responsive("TABLET") {
      width: $filter-height;
      height: $filter-height;
      .role-label {
        display: none;
      }
    }
    background-color: #1d1d39;
    &.active {
      background-color: $filter-color;
    }
    &:hover {
      background-color: $filter-hover-color;
      cursor: pointer;
    }
    &:first-child {
      border-top-left-radius: $filter-border-radius;
      border-bottom-left-radius: $filter-border-radius;
    }
    &:last-child {
      border-top-right-radius: $filter-border-radius;
      border-bottom-right-radius: $filter-border-radius;
    }
    svg {
      width: 14px;
    }
  }
}

.landing_filter_role-split {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px 1px;
  background-color: #070720;

  .role-option {
    @include flex-center;
    width: 95px;
    height: 36px;
    background-color: #1d1d39;
    &.active {
      background-color: $filter-color;
    }
    &:hover {
      background-color: $filter-hover-color;
      cursor: pointer;
    }
    &:first-child {
      border-top-left-radius: $filter-border-radius;
      border-bottom-left-radius: $filter-border-radius;
    }
    &:last-child {
      border-top-right-radius: $filter-border-radius;
      border-bottom-right-radius: $filter-border-radius;
    }
    svg {
      width: 14px;
    }
  }
}

.filter-worlds {
  @include flex-center;
  width: 110px;
  height: 36px;
  background-color: #1d1d39;
  border-radius: 2px;
  font-size: 12px;

  &:hover {
    background-color: $filter-hover-color;
    cursor: pointer;
  }

  .world-logo {
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }
}

.filter-otp {
  @include flex-center;
  width: 70px;
  height: 36px;
  background-color: #1d1d39;
  border-radius: 2px;
  font-size: 12px;

  &:hover {
    background-color: $filter-hover-color;
    cursor: pointer;
  }
}

.filter_league {
  // width: 100px;

  .league-value {
    display: flex;
    //align-items: center;

    //text-align: left;
    //width: 13px;
    height: 18px;
    color: #ffffff;
    font-family: "Inter";
    font-size: 11px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    align-items: center;
    //justify-content: center;
    //text-transform: uppercase;
    div {
      /*height: 15px;
      width: 65px;
      color: #ffffff;
      font-family: "Inter";
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;*/
      //text-align: center;
      margin-left: 8px;
      //margin-top: 3px;
    }
    img {
      //margin-top: -4px;
      min-width: 18px;
      width: 18px;
    }
  }
}

.filter_region {
  .region-value {
    display: flex;
    align-items: center;

    img {
      width: 24px;
    }
  }
}
.filter_team {
  min-width: 100px;
  .filter-select__option {
    display: flex;
    align-items: center;
    min-height: 40px;
    overflow-x: hidden;
  }
  .team-value {
    display: flex;
    align-items: center;

    img {
      width: 24px;
    }
  }
}

.filter_matchup {
  @include flex-center;
  position: relative;
  min-width: 72px;
  max-width: 170px;
  height: $filter-height;
  background-color: $filter-color;
  border-radius: $filter-border-radius;
  font-size: 13px;

  &:hover {
    background-color: $filter-hover-color;
    cursor: pointer;
  }

  .matchup-control {
    @include flex-center;
    flex: 1;
    padding: 0 12px;
    height: $filter-height;
    white-space: nowrap;

    span.vs {
      margin-right: 6px;
    }
    .matchup-label {
      max-width: 120px;

      input {
        width: 100%;
        max-width: 120px;
        background-color: transparent;
        outline: none;
        border: none;
        color: #ffffff;

        &::placeholder {
          color: #bec3e1;
        }
      }
      .matchup-champion {
        .champion-image {
          @include champion-image-scale;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          overflow: hidden;
        }
        .all-champions {
          display: flex;
          align-items: center;
          padding: 0 12px;
          height: 24px;
          border-radius: 6px;
          background-color: #1d1d39;

          svg {
            margin-left: 6px;
            width: 7px;
            flex: 0 0 7px;
          }
        }
      }
    }

    .clear-matchup {
      @include flex-center;
      margin-left: 12px;
      padding: 4px;
      background-color: #77779f;
      border-radius: 50%;

      &:hover {
        opacity: 0.8;
      }

      svg {
        width: 8px;
        fill: #ffffff;
      }
    }
  }

  .matchup-menu {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    right: 0;
    background-color: $filter-color;
    border-radius: $filter-border-radius;

    .matchup-menu_item {
      display: flex;
      align-items: center;
      padding: 8px 12px;

      &.highlighted {
        background-color: $filter-hover-color;
      }

      img {
        margin-right: 12px;
        width: 26px;
        height: 26px;
      }

      .champion-name {
        font-size: 13px;
      }
    }
  }
}
