#footer {
  display: flex;
  flex-direction: column;
  margin-top: 90px;
  padding-top: 60px;
  width: 100%;
  background-size: max(100%, 1000px);
  background-repeat: no-repeat;
  background-position: 50% 10px;
  background-image: url("https://static.bigbrain.gg/assets/probuildstats/pages/paint-bg.png");

  .footer-bg {
    padding: 30px 0 60px;
    background-color: #181834;
  }

  .footer-container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    @include page-width();

    @include responsive("DESKTOP_SMALL") {
      @include page-width("DESKTOP_SMALL");
    }
    @include responsive("TABLET") {
      @include page-width("TABLET");
    }
    @include responsive("MOBILE_LARGE") {
      @include page-width("MOBILE_LARGE");
      flex-direction: column;
    }
    @include responsive("MOBILE_SMALL") {
      @include page-width("MOBILE_SMALL");
    }

    .footer_section {
      display: flex;
      flex-direction: column;
      padding-bottom: 24px;
      max-width: 213px;
      border-bottom: 1px solid #f8b218;
      font-size: 14px;

      @include responsive("MOBILE_LARGE") {
        max-width: none;
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }

      .footer_header {
        margin-bottom: 20px;
        font-family: "Inter";
        font-size: 27px;
        font-weight: 500;
      }
    }

    .disclaimers {
      .footer_header {
        font-weight: 700;
      }
      .copyright {
        margin-bottom: 16px;
        color: #bec3e1;
        font-weight: 500;
      }
      .disclaimer {
        color: lighten(#7777a4, 10%);
        line-height: 18px;
      }
    }
    .network {
      .ugg-logo {
        width: 67px;
      }
      .links {
        display: flex;
        flex-direction: column;
        margin-bottom: -16px;

        @include responsive("MOBILE_LARGE") {
          flex-direction: row;
          flex-wrap: wrap;
          a {
            margin-right: 12px;
          }
        }
        a {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          height: 18px;
          color: #bec3e1;
          font-weight: 500;
          white-space: nowrap;

          &:hover {
            color: #ffffff;
          }
        }
      }
    }
    .connect-with-us {
      .social-links {
        display: flex;
        flex-direction: column;
        margin-bottom: -16px;

        @include responsive("MOBILE_LARGE") {
          flex-direction: row;
          flex-wrap: wrap;
          .social-link {
            margin-right: 12px;
            margin-bottom: 0;
            min-width: 100px;
          }
        }
        .social-link {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          color: #bec3e1;
          font-weight: 500;
          &:hover {
            color: #ffffff;
          }
          svg {
            margin-right: 10px;
            width: 18px;
            fill: #f8b218;
          }
        }
      }
    }
  }
}
