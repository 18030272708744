.popular-champions {
  .content {
    display: grid;
    grid-template-columns: repeat(10, auto);
    grid-gap: 36px 46px;

    @media (max-width: 1230px) {
      grid-template-columns: repeat(5, auto);
    }
    @media (max-width: 630px) {
      grid-template-columns: repeat(3, auto);

      .champion:last-child {
        display: none;
      }
    }
  }

  .champion {
    &:hover {
      .champion-img-container {
        top: -4px;
        // opacity: 0.8;
      }
      .champion-name {
        color: #bec3e1;
        // text-decoration: underline;
      }
    }
    .champion-img-container {
      @include flex-center;
      position: relative;
      top: 0;
      transition: top 0.2s;

      .border {
        position: absolute;
        z-index: 0;
      }
      .champion-img {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 78px;
        height: 78px;
        border-radius: 50%;
        border: 3px solid #070720;
        background-color: #070720;
        overflow: hidden;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          transform: translate(-50%, -50%) scale(1.1);
        }
      }
    }
    .champion-name {
      margin-top: 12px;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
    }
    .pick-rate {
      margin-top: 7px;
      font-size: 14px;
      color: #bec3e1;
      text-align: center;
    }
  }
}
