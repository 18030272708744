.trashcan-page {
  display: flex;
  flex-direction: column;

  &.trashcan-page_error {
    padding-top: 40px;
    .message {
      display: flex;
      align-items: flex-start;
      justify-content: center;

      img {
        position: relative;
        top: 3px;
        margin-right: 12px;
      }

      h1 {
        margin-bottom: 12px;
        font-size: 20px;
        font-weight: 500;
      }
      span {
        color: #bec3e1;
        font-size: 16px;
      }
    }
  }

  h5 {
    margin-bottom: 14px;
    font-size: 16px;
    font-weight: 500;
  }
  .para {
    margin-bottom: 14px;
    color: #bec3e1;
    font-size: 16px;
    font-weight: 400px;

    .custom-checkbox {
      position: relative;
      top: -2px;
    }
  }

  .trash-search {
    display: flex;
    margin-top: 10px;
    margin-bottom: 24px;
    padding: 0 24px;
    max-width: 384px;
    width: 100%;
    height: 36px;
    border-radius: 18px;
    background-color: #1d1d39;

    .search-icon {
      width: 16px;
      fill: #ffffff;
    }
    input {
      padding-left: 24px;
      width: 100%;
      border: none;
      outline: none;
      background: transparent;
      font-size: 14px;
      color: #ffffff;

      &::placeholder {
        color: #ffffff;
      }
    }
  }

  .trash-buckets {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(3, calc(33.33% - 16px));
    grid-gap: 0 24px;

    .bucket {
      display: flex;
      flex-direction: column;

      .bucket_title {
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: 500;
      }

      .bucket_content {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 24px;
        border-radius: 4px;
        background-color: #1d1d39;

        ul {
          flex: 1;
          // max-height: 401px;
          max-height: 501px;
          margin: -12px 0 24px 0;
          padding: 0;
          list-style: none;
          overflow: auto;

          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 60px;
            width: 100%;
            border-bottom: 1px solid #454566;

            .custom-checkbox {
              height: 100%;
              width: 100%;
              .label {
                flex: 1;
              }
            }

            .trash-item {
              display: flex;
              align-items: center;
              margin-right: 12px;

              .trash-item_label {
                font-family: "Inter";
                font-size: 14px;
                font-weight: 700;
                text-transform: uppercase;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }

              .trash-item_image-container {
                @include flex-center;
                margin-right: 12px;
                flex: 0 0 34px;
                width: 34px;
                height: 34px;
                border-radius: 50%;
                background-color: #333354;
                overflow: hidden;

                .player-image {
                  width: 40px;
                  flex: 0 0 40px;
                }
                .custom-image-container {
                  @include flex-center;
                  .team-image {
                    width: 24px;
                    flex: 0 0 24px;
                  }
                }
                .league-image {
                  width: 24px;
                  flex: 0 0 24px;
                }
              }

              .player-info {
                overflow: hidden;
                .player-info_team-image {
                  display: flex;
                  align-items: center;
                  width: 14px;
                  flex: 0 0 14px;
                }
                .player-info_team-name {
                  margin-left: 4px;
                  color: #bec3e1;
                  font-size: 12px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }

        .update-btn {
          @include flex-center;
          width: 100%;
          height: 36px;
          border-radius: 2px;
          background-color: #333354;
          color: #ffffff;
          font-size: 13px;
          font-weight: 500;

          &:hover {
            background-color: #3273fa;
            cursor: pointer;
          }

          &.save-state {
            background-color: #229a71;
          }

          svg {
            margin-right: 6px;
            width: 9px;
          }
        }
      }
    }
  }
}

@keyframes animate-open {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.modal-open-animate {
  animation: 0.25s ease-in-out animate-open;
}

.trashcan-modal {
  position: fixed;
  z-index: 9999999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(#070720, 0.8);

  .modal-window {
    position: relative;
    padding: 24px;
    width: 334px;
    border-radius: 6px;
    background-color: #1d1d39;

    .close {
      position: absolute;
      top: 26px;
      right: 26px;
      width: 14px;

      path {
        fill: #bec3e1;
      }

      &:hover {
        cursor: pointer;
        path {
          fill: #ffffff;
        }
      }
    }

    h5 {
      margin-bottom: 18px;
      font-size: 16px;
    }

    .para {
      margin-bottom: 24px;
      color: #bec3e1;
      font-size: 14px;
      line-height: 21px;
    }

    .cog-icon {
      position: relative;
      top: 3px;
      width: 15px;
      path {
        fill: #ffffff;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      border-top: 1px solid #454566;

      li {
        display: flex;
        align-items: center;
        padding: 12px 0;
        border-bottom: 1px solid #454566;

        .custom-checkbox {
          width: 100%;
        }

        .trash-modal_item {
          flex: 1;
          display: flex;
          align-items: center;
          margin-right: 12px;

          .image-container {
            @include flex-center;
            margin-right: 12px;
            flex: 0 0 34px;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            background-color: #333354;
            overflow: hidden;

            .player-image {
              width: 40px;
              flex: 0 0 40px;
            }
            .custom-image-container {
              @include flex-center;
              .team-image {
                width: 24px;
                flex: 0 0 24px;
              }
            }
            .league-image {
              width: 24px;
              flex: 0 0 24px;
            }
          }
          .info {
            .item_label {
              font-family: "Inter";
              color: #ffffff;
              font-size: 14px;
              font-weight: 700;
              text-transform: uppercase;
            }
            .item_type {
              color: #bec3e1;
              font-size: 12px;
            }
          }
        }
      }
    }

    .confirm-btn {
      @include flex-center;
      margin-top: 24px;
      width: 100%;
      height: 36px;
      border-radius: 2px;
      background-color: #333354;
      color: #ffffff;
      font-size: 13px;
      font-weight: 500;

      &:hover {
        background-color: #3273fa;
        cursor: pointer;
      }

      svg {
        margin-right: 6px;
        width: 9px;
      }
    }
  }
}
