/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * eurostile:
 *   - http://typekit.com/eulas/00000000000000003b9b2159
 *   - http://typekit.com/eulas/00000000000000003b9b2163
 *   - http://typekit.com/eulas/00000000000000003b9b2165
 *   - http://typekit.com/eulas/00000000000000003b9b2169
 *   - http://typekit.com/eulas/00000000000000003b9b2160
 *   - http://typekit.com/eulas/00000000000000003b9b215a
 *   - http://typekit.com/eulas/00000000000000003b9b2162
 *   - http://typekit.com/eulas/00000000000000003b9b2164
 *   - http://typekit.com/eulas/00000000000000003b9b2168
 *   - http://typekit.com/eulas/00000000000000003b9b215f
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2020-08-04 16:32:43 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=flk2mqz&ht=tk&f=25750.25753.25761.25762.25763.25764.25765.25766.25767.25768&a=10871835&app=typekit&e=css");

@font-face {
  font-family: "eurostile";
  src: url("https://use.typekit.net/af/2d8a3b/00000000000000003b9b2159/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/2d8a3b/00000000000000003b9b2159/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/2d8a3b/00000000000000003b9b2159/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "eurostile";
  src: url("https://use.typekit.net/af/6be0f8/00000000000000003b9b2163/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/6be0f8/00000000000000003b9b2163/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/6be0f8/00000000000000003b9b2163/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "eurostile";
  src: url("https://use.typekit.net/af/741689/00000000000000003b9b2165/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/741689/00000000000000003b9b2165/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3")
      format("woff"),
    url("https://use.typekit.net/af/741689/00000000000000003b9b2165/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "eurostile";
  src: url("https://use.typekit.net/af/463c95/00000000000000003b9b2169/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/463c95/00000000000000003b9b2169/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3")
      format("woff"),
    url("https://use.typekit.net/af/463c95/00000000000000003b9b2169/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "eurostile";
  src: url("https://use.typekit.net/af/c6e0fb/00000000000000003b9b2160/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/c6e0fb/00000000000000003b9b2160/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/c6e0fb/00000000000000003b9b2160/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "eurostile";
  src: url("https://use.typekit.net/af/d262e5/00000000000000003b9b215a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/d262e5/00000000000000003b9b215a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/d262e5/00000000000000003b9b215a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "eurostile";
  src: url("https://use.typekit.net/af/198f80/00000000000000003b9b2162/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/198f80/00000000000000003b9b2162/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/198f80/00000000000000003b9b2162/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "eurostile";
  src: url("https://use.typekit.net/af/5f50fa/00000000000000003b9b2164/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/5f50fa/00000000000000003b9b2164/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3")
      format("woff"),
    url("https://use.typekit.net/af/5f50fa/00000000000000003b9b2164/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: "eurostile";
  src: url("https://use.typekit.net/af/08504c/00000000000000003b9b2168/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/08504c/00000000000000003b9b2168/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff"),
    url("https://use.typekit.net/af/08504c/00000000000000003b9b2168/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: "eurostile";
  src: url("https://use.typekit.net/af/418096/00000000000000003b9b215f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/418096/00000000000000003b9b215f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/418096/00000000000000003b9b215f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 500;
}

.tk-eurostile {
  font-family: "eurostile", sans-serif;
}
