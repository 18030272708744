.pro-home-page {
  .pro-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(163px, 1fr));
    grid-gap: 22px;
    justify-content: center;
    padding: 22px;
    border-radius: 4px;
    background-color: #111129;

    @include responsive("MOBILE_SMALL") {
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
      grid-gap: 16px;
      padding: 12px;
    }

    .pro-link {
      display: flex;
      align-items: center;
      padding: 0 12px;
      height: 60px;
      border-radius: 4px;
      background-color: #1d1d39;

      &:hover {
        background-color: #333354;
      }

      .headshot-container {
        position: relative;
        width: 36px;
        height: 36px;

        .pro-headshot {
          width: 36px;
          height: 36px;
          background-color: #43435d;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 56px;
            height: 44px;
            margin-bottom: -6px;
          }
        }

        .league-icon {
          position: absolute;
          top: -6px;
          right: -6px;
          @include flex-center;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background-color: #111129;

          > img {
            flex: 0 0 16px;
            width: 16px;
          }
        }
      }
    }

    .player-info {
      margin-left: 12px;
      overflow: hidden;

      .player-name {
        font-size: 16px;
        font-weight: 800;
        text-transform: uppercase;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .player-team {
        display: flex;
        align-items: center;

        .team-icon {
          flex: 0 0 16px;
          width: 16px;
          height: 16px;

          .custom-image-container {
            height: 16px;
            width: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          img {
            width: 16px;
          }
        }
        .team-name {
          margin-left: 4px;
          color: #bec3e1;
          font-size: 14px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}
